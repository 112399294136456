import React from "react";
import NavbarMobile from "./navbar-mobile";
import NavbarWeb from "./navbar-web";
import "./navbar.css";

const Navbar = ({ isMobileVersion }) => {

  const stickyClass = !isMobileVersion ? "sticky" : "";

  return (
    <div className={`navbar-container ${stickyClass}`}>
      {!isMobileVersion && <NavbarWeb />}
      {isMobileVersion && <NavbarMobile />}
    </div>
  );
};

export default Navbar;
