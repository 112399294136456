import React from "react";
import MobileBody from "./mobile-body";
import WebBody from "./web-body";
import "./body.css";
import { Helmet } from "react-helmet";

const Body = ({ isMobileVersion }) => {
  return (
    <div>
      <Helmet>
        <title>Sucosan | Materiales de construcción en Madrid</title>
        <meta
          name="description"
          content="Sucosan es la tienda de referencia de materiales de construcción del centro de Madrid. Trabajamos con las mejores marcas y hacemos envíos a toda la Península."
        />
        <link rel="canonical" href="https://www.sucosan.com" />
      </Helmet>

      {isMobileVersion && (
        <div className="mobile-body">
          <MobileBody />
        </div>
      )}
      {!isMobileVersion && (
        <div className="mobile-body">
          <WebBody />
        </div>
      )}
    </div>
  );
};

export default Body;
