import React from "react";
import MenuStripWithArrow from "./menu-strip-mobile-arrow";
import "./menu-strip.css";
import { emptyMenu, catalogInformation } from "../navbar/navbar-data";

const MenuStripMobile = () => {
  return (
    <div className="menu-strip-mobile-container">
      <MenuStripWithArrow title="INICIO" linkTo="/" menu={emptyMenu} />

      <MenuStripWithArrow
        title="SOBRE SUCOSAN"
        linkTo="/sobre-sucosan"
        menu={emptyMenu}
        showArrow={false}
      />

      <MenuStripWithArrow
        title="CATÁLOGO"
        linkTo="/catalogo"
        menu={catalogInformation}
        showArrow={true}
      />

      <MenuStripWithArrow
        title="OFERTAS"
        linkTo="/ofertas"
        menu={emptyMenu}
        showArrow={false}
      />

      <MenuStripWithArrow
        title="PIDE PRESUPUESTO"
        linkTo="/presupuesto"
        menu={emptyMenu}
        showArrow={false}
      />

      <MenuStripWithArrow
        title="CONTACTO"
        linkTo="/contacto"
        menu={emptyMenu}
        showArrow={false}
      />

      <MenuStripWithArrow
        title="TIENDA ONLINE"
        linkTo="/tienda-online"
        menu={emptyMenu}
        showArrow={false}
      />
    </div>
  );
};

export default MenuStripMobile;
