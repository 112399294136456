import React from "react";
import "./about.css";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className="about-container">
      <Helmet>
        <title>Sobre Sucosan | Materiales de construcción en Madrid</title>
        <meta
          name="description"
          content="Sucosan es uno de los principales almacenes de materiales de construcción de la zona centro de Madrid. Ofrecemos servicio de transporte, recogida de escombro y mucho más."
        />
      </Helmet>
      <h2 className="about-title">Sobre Sucosan</h2>
      <img
        className="about-image"
        src="https://i.ibb.co/B6yVsdy/sobre-sucosan.jpg"
        alt="Sucosan es el almacén de materiales de construcción de referencia de la zona centro de Madrid, aunque ofrece servicio de transporte a toda España."
      />
      <p className="about-text about-mb-separator">
        Sucosan es en la actualidad uno de los principales almacenes de
        materiales de construcción de la zona centro de Madrid.
      </p>

      <h3 className="about-section-text">Nuestra historia</h3>
      <p className="about-text">
        Contamos con una amplia trayectoria y una larga experiencia que se
        remontan a principios del siglo XX, que es cuando se creó la empresa
        bajo la marca MABUGA.
      </p>
      <p className="about-text">
        Ya entonces era un negocio destinado a la venta de materiales para la
        construcción y reforma, aunque a medida que han transcurrido los años ha
        ido evolucionando generación tras generación.
      </p>
      <p className="about-text about-mb-separator">
        Es en el año 1997 cuando se crea la marca Sucosan a manos de la tercera
        generación.
      </p>

      <h3 className="about-section-text">Sucosan en la actualidad</h3>
      <p className="about-text">
        Sucosan está situada en una zona privilegiada de Madrid. La ubicación de
        nuestro punto de venta y nuestro depósito es inmejorable, ya que se
        encuentra en pleno centro de la capital.
      </p>
      <p className="about-text about-mb-separator">
        Desde allí suministramos todo tipo de materiales de construcción a toda
        la Comunidad de Madrid y el resto de zonas de la España Peninsular,
        tarea para la cual disponemos de camiones y furgonetas de reparto.
      </p>

      <h3 className="about-section-text">Servicios</h3>
      <p className="about-text">
        Ponemos a tu disposición una serie de servicios como:{" "}
      </p>

      <ul>
        <li>
          Transporte o entrega en el punto elegido con camiones grúa y
          furgonetas.
        </li>
        <li>
          Servicio de recogida de escombro en toda la Comunidad de Madrid.
        </li>
        <li>
          Asesoramiento experto en caso de dudas acerca de material elegir.
        </li>
        <li>
          Amplia variedad de productos para que encuentres en el que mejor se
          adapta a tus necesidades.
        </li>
        <li>Ponemos a tu disposición las mejores marcas del mercado.</li>
        <li>
          Gestionamos tus pedidos con rapidez para que pueda llegar a tiempo a
          su destino.
        </li>
      </ul>

      <div className="about-buttons-container">
        <a href="/catalogo" className="about-button">
          VER PRODUCTOS
        </a>
        <a href="/presupuesto" className="about-button">
          PEDIR PRESUPUESTO
        </a>
      </div>
    </div>
  );
};

export default About;
