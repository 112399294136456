import React, { useState } from "react";
import "./budget.css";
import { sendBudgetEmail } from "../../services/sucosan-service";
import { Helmet } from "react-helmet";

const Budget = () => {
  const [data, setData] = useState({
    name: "",
    company: "",
    email: "",
    products: "",
    transport: "",
    address: "",
  });

  const [emailInfo, setEmailInfo] = useState({
    sent: false,
    error: false,
    sending: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailInfo({ ...data, sending: true });
    sendBudgetEmail(data)
      .then((result) => {
        setData({
          name: "",
          company: "",
          email: "",
          products: "",
          transport: "",
          address: "",
        });
        setEmailInfo({ sent: true, error: false, sending: false });
        if (result === "ERROR") {
          setEmailInfo({ sent: false, error: true, sending: false });
        }
      })
      .catch((error) =>
        setEmailInfo({ sent: false, error: true, sending: false })
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  return (
    <div className="budget-container">
      <Helmet>
        <title>Materiales de construcción | Pide presupuesto | Sucosan</title>
        <meta
          name="description"
          content="En Sucosan encontrarás todos los materiales de construcción necesarios para llevar a cabo tu obra o reforma. Pide presupuesto sin compromiso y recuerda que disponemos de servicio de transporte si necesitas que te lo llevemos."
        />
      </Helmet>
      <h2 className="budget-title">Pide presupuesto</h2>
      {emailInfo.sent && (
        <p className="budget-email-sent">
          Presupuesto solicitado correctamente!
        </p>
      )}
      {emailInfo.error && (
        <p className="budget-email-sent-error">
          Hubo un error al enviar el correo. Intentarlo más tarde.
        </p>
      )}
      {emailInfo.sending && (
        <p className="budget-email-sending">Enviando el correo...</p>
      )}
      <p className="budget-description">
        ¿Estás interesado en alguno de nuestros productos y materiales para la
        construcción? Pide presupuesto de forma rápida y sencilla completando el
        siguiente formulario.
        <br />
        <br />
        Nuestro equipo comercial contactará contigo lo antes posible para darte
        respuesta
      </p>

      <form
        onSubmit={handleSubmit}
        className="budget-form-container"
        autoComplete="off"
      >
        <label className="budget-form-input-container small-input">
          <span className="budget-form-title">Nombre</span>
          <input
            id="name"
            className="budget-form-data"
            type="text"
            name="name"
            value={data.name}
            onChange={handleChange}
            placeholder="Escribe tu nombre completo"
            required={true}
          />
        </label>

        <label className="budget-form-input-container small-input">
          <span className="budget-form-title">Empresa</span>
          <input
            id="company"
            className="budget-form-data"
            type="text"
            name="company"
            value={data.company}
            onChange={handleChange}
            placeholder="Indica el nombre de tu empresa"
          />
        </label>

        <label className="budget-form-input-container">
          <span className="budget-form-title">Email</span>
          <input
            id="email"
            className="budget-form-data"
            type="email"
            name="email"
            value={data.email}
            onChange={handleChange}
            placeholder="Escribe el correo electrónico al que quieras recibir nuestra respuesta"
            required={true}
          />
        </label>

        <label className="budget-form-input-container">
          <span className="budget-form-title">Productos</span>
          <textarea
            id="products"
            className="budget-form-data"
            name="products"
            value={data.products}
            onChange={handleChange}
            placeholder="¿En qué productos estás interesado y qué cantidad? Cuantos más detalles nos ofrezcas, más ajustado será el presupuesto que te enviaremos"
            required={true}
          />
        </label>

        <label className="budget-form-input-container thirty-input">
          <span className="budget-form-title">¿Necesitas transporte?</span>
          <input
            id="transport"
            className="budget-form-data"
            type="text"
            name="transport"
            value={data.transport}
            placeholder="Sí/No"
            onChange={handleChange}
          />
        </label>

        <label className="budget-form-input-container seventy-input">
          <span className="budget-form-title">¿Dónde lo enviamos?</span>
          <input
            id="address"
            className="budget-form-data"
            type="text"
            name="address"
            value={data.address}
            onChange={handleChange}
            placeholder="Escribe la dirección exacta"
            required={true}
          />
        </label>

        <button className="budget-form-button">Solicitar presupuesto</button>
      </form>
    </div>
  );
};

export default Budget;
