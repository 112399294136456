import React, { useState } from "react";

const MenuStripSubMenu = ({ firstMenu, secondMenu, it }) => {
  const [showSecondSubMenu, setShowSecondSubMenu] = useState(false);
  const selectedOption = showSecondSubMenu ? "color-black" : "";

  const HandleshowSecondSubMenu = () => {
    setShowSecondSubMenu(!showSecondSubMenu);
  };

  return (
    <div className="menu-strip-mobile-submenu-container mr-10">
      {/* FIRST SUB MENU */}
      <div className="menu-strip-mobile-first-submenu ml-10">
        <div
          className={`menu-strip-mobile-first-submenu-option ${selectedOption}`}
        >
          <a className={`${selectedOption}`} href={`/catalogo/` + firstMenu.ref}>{firstMenu.title}</a>

          {!showSecondSubMenu && firstMenu.menu.length > 0 && (
            <i
              className={`fa fa-sort-down ${selectedOption}`}
              onClick={HandleshowSecondSubMenu}
            ></i>
          )}
          {showSecondSubMenu && (
            <i className="fa fa-sort-up" onClick={HandleshowSecondSubMenu}></i>
          )}
        </div>
      </div>

      {/* SECOND SUB MENU */}
      {showSecondSubMenu && (
        <div className="menu-strip-mobile-second-submenu">
          {secondMenu[it].map((secondMenuOption, i) => (
            <a
              href={`/catalogo/` + firstMenu.ref + `/` + secondMenuOption.ref}
              className="menu-strip-mobile-second-submenu-option"
              key={secondMenuOption.title}
            >
              {secondMenuOption.title}
            </a>
          ))}
        </div>
      )}
      <div className="ml-10">
        <div className="divider"></div>
      </div>
    </div>
  );
};

export default MenuStripSubMenu;

// import React, { useState } from "react";

// const MenuStripSubMenu = ({ subMenuObject }) => {
//   const [showSecondSubMenu, setShowSecondSubMenu] = useState(false);
//   const firstSubMenu = subMenuObject ? Object.keys(subMenuObject) : [];
//   const secondSubMenu = subMenuObject ? Object.values(subMenuObject) : [];
//   const selectedOption = showSecondSubMenu ? "color-black" : "";

//   const HandleshowSecondSubMenu = () => {
//     setShowSecondSubMenu(!showSecondSubMenu);
//   };

//   return (
//     <div className="menu-strip-mobile-submenu-container mr-10">
//       {/* FIRST SUB MENU */}
//       <div className="menu-strip-mobile-first-submenu ml-10">
//         {firstSubMenu.map((el, i) => (
//           <div
//             className={`menu-strip-mobile-first-submenu-option ${selectedOption}`}
//             key={el}
//           >
//             <div>{el}</div>
//             {!showSecondSubMenu && secondSubMenu[0].length > 0 && (
//               <i
//                 className={`fa fa-sort-down ${selectedOption}`}
//                 onClick={HandleshowSecondSubMenu}
//               ></i>
//             )}
//             {showSecondSubMenu && (
//               <i
//                 className="fa fa-sort-up"
//                 onClick={HandleshowSecondSubMenu}
//               ></i>
//             )}
//           </div>
//         ))}
//       </div>

//       {/* SECOND SUB MENU */}
//       {showSecondSubMenu && (
//         <div className="menu-strip-mobile-second-submenu">
//           {secondSubMenu[0].map((el, i) => (
//             <div className="menu-strip-mobile-second-submenu-option" key={el}>
//               {el}
//             </div>
//           ))}
//         </div>
//       )}
//       <div className="ml-10">
//         <div className="divider"></div>
//       </div>
//     </div>
//   );
// };

// export default MenuStripSubMenu;
