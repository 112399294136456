import React, { useState, useEffect } from "react";
import "./catalog-menu.css";
import { catalogInformation } from "../navbar/navbar-data";
import { Redirect, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const CatalogMenu = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [optionMenu, setOptionMenu] = useState([]);

  const checkMenuUrl = () => {
    const menuUrl = props.match.params.menu;
    const catalogOptionCorrect = Object.values(catalogInformation).some(
      (opt) => opt["ref"] === menuUrl
    );

    if (!catalogOptionCorrect && !redirect) {
      setRedirect(true);
    } else {
      if (optionMenu.length === 0) {
        const catalogOption = Object.values(catalogInformation).filter(
          (opt) => opt["ref"] === menuUrl
        );
        setOptionMenu(catalogOption[0]);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  checkMenuUrl();

  if (!optionMenu.menu) {
    return <div>Cargando...</div>;
  }

  if (redirect) {
    return <Redirect to="/catalogo" />;
  }

  return (
    <div className="catalog-menu-container">
      <Helmet>
        <title>{optionMenu.seoTitle}</title>
        <meta name="description" content={optionMenu.seoDescription} />
      </Helmet>
      <div className="catalog-menu-info-container">
        <h2 className="catalog-menu-title">{optionMenu.title}</h2>
        <div className="catalog-menu-info">
          <p className="catalog-menu-description">{optionMenu.description}</p>
          {optionMenu.title === "Fila" && (
            <a
              href="https://www.filasolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="catalog-menu-link"
            >
              <img
                className="catalog-menu-img-fila"
                src={optionMenu.image}
                alt={optionMenu.seoAltTextImage}
              />
            </a>
          )}
          {optionMenu.title !== "Fila" && (
            <img
              className="catalog-menu-img"
              src={optionMenu.image}
              alt={optionMenu.seoAltTextImage}
            />
          )}
        </div>
      </div>
      {optionMenu.menu.length > 0 && (
        <ul className="catalog-menu-submenu">
          {optionMenu.menu.map((opt, i) => {
            return (
              <Link
                to={
                  `/catalogo/` + optionMenu.ref + `/` + optionMenu.menu[i].ref
                }
                className="catalaog-menu-submenu-option"
                key={i}
              >
                <li>{optionMenu.menu[i].title}</li>
              </Link>
            );
          })}
        </ul>
      )}
      <ul className="catalog-menu-direct-submenu">
        {optionMenu.directSubmenu.map((opt, i) => {
          return (
            <a
              href={opt.redirect}
              className="catalog-direct-submenu-option"
              key={i}
              target="_blank"
              rel="noopener noreferrer "
            >
              <div className="catalog-submenu-option-img-container">
                <img src={opt.img} alt={opt.alt} />
              </div>
              <p>{opt.title}</p>
            </a>
          );
        })}
      </ul>

      <div className="catalog-menu-buttons-container">
        <a className="catalog-menu-button" href="/presupuesto">
          PEDIR PRESUPUESTO
        </a>
      </div>

      {optionMenu.title === "Fontanería" && (
        <div className="catalog-menu-prices">
          <p>
            *Los precios que aparecen en cada catálogo no son los precios
            finales, sino orientativos. Para conocer el precio definitivo, por
            favor, <a href="/presupuesto">contacta con nuestro equipo</a>
          </p>
          <p className="catalog-menu-prices-specific">
            ** Únicamente trabajamos con los productos de ventilación/extracción
            de Elinsa
          </p>
        </div>
      )}

      {optionMenu.title !== "Fontanería" && (
        <p className="catalog-menu-prices">
          *Los precios que aparecen en cada catálogo no son los precios finales,
          sino orientativos. Para conocer el precio definitivo, por favor,{" "}
          <a href="/presupuesto">contacta con nuestro equipo</a>
        </p>
      )}
    </div>
  );
};

export default CatalogMenu;
