import React from "react";
import "./offers.css";
import { Helmet } from "react-helmet";
import { products } from "./offers-data";
import { Link } from 'react-router-dom'

const Offers = () => {
  return (
    <div className="offers-container">
      <Helmet>
        <title>Ofertas Sucosan | Materiales de construcción en Madrid</title>
        <meta
          name="description"
          content="En Sucosan ponemos a tu disposición ofertas de los mejores materiales de construcción y productos y herramientas para la reforma."
        />
      </Helmet>

      <h2 className="offers-title">Ofertas</h2>
      <p className="offers-text">
        En Sucosan ponemos a tu disposición ofertas para productos de las
        mejores marcas de materiales de construcción y herramientas para la obra
        y reforma. Para estar al día de todas las novedades y no quedarte sin
        tus productos favoritos en oferta, te recomendamos que accedas a esta
        página frecuentamente.
      </p>
      <p className="offers-text">
        Ofertas disponibles hasta fin de existencias
      </p>

      <div className="offers-products-container">
        {products.map((product, i) => (
          <div className="offers-card" key={i}>
            <img
              className="offers-card-img"
              src={product.img}
              alt={product.seoAltTextImage}
            />
            <div className="offers-description-container">
              <div className="offers-text-container">
                <h2 className="offers-card-title">{product.name}</h2>
                <p>
                  <span className="offers-card-price">{product.price}</span> +
                  IVA
                </p>
              </div>

              <div className="offers-button-container">
                <Link className="offers-button" to={`/ofertas/${product.url}`}>
                  VER DETALLES
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="offers-button-container">
        <a className="offers-button-budget" href="/presupuesto">
          HACER PEDIDO
        </a>
      </div>
    </div>
  );
};

export default Offers;
