import { logos } from "../../utilities/logos";

export const emptyMenu = [];

export const catalogInformation = [
  {
    title: "Construcción",
    seoTitle: "Catálogo | Materiales de construcción en Madrid",
    description:
      "En Sucosan trabajamos con las mejores marcas para poner a tu disposición un amplio catálogo de materiales de construcción de la mejor calidad. Por eso, sólo trabajamos con fábricas que cumplan todas las normativas en la fabricación de este tipo de materiales, las certificaciones europeas pertinentes (CE) y los más estrictos controles de calidad. Desde nuestro almacén del centro de Madrid distribuimos materiales de construcción a toda España, salvo las islas Baleares y Canarias",
    seoDescription:
      "Sucosan está especializado en productos para la construcción y la reforma, por lo que disponemos de un amplio catálogo de productos de este tipo.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1622655479/sucosan/construccion-sucosan_s8s9mu.jpg",
    seoAltTextImage:
      "listado de productos y materiales de construcción de Sucosan en Madrid.",
    ref: "construccion",
    menu: [
      {
        title: "Cementos y morteros",
        seoTitle: "Catálogo | Construcción | Cementos y morteros Madrid",
        description:
          "Sucosan cuenta con los principales tipos de cementos y morteros, como el cemento blanco o el cemento gris, y con una amplia gama de morteros tradicionales y revocos a la cal.",
        seoDescription:
          "Compra cementos y morteros para la construcción de la mejor calidad. Puedes recogerlos en nuestro almacén de Madrid o podemos llevarlos donde tú quieras.",
        ref: "cementos-morteros",
        options: [
          {
            title: "Ver catálogo del Grupo Puma",
            img: logos.grupoPuma.url,
            alt: "listado de cementos y morteros de Sucosan en Madrid de la marca Grupo Puma. Consulta su catálogo.",
            redirect: logos.grupoPuma.redirect,
          },
          {
            title: "Ver catálogo de Gecol",
            img: logos.gecol.url,
            alt: "listado de cementos y morteros de Sucosan en Madrid de la marca Gecol. Consulta su catálogo.",
            redirect: logos.gecol.redirect,
          },
        ],
      },
      {
        title: "Pegamentos",
        seoTitle: "Catálogo | Construcción | Pegamentos Madrid",
        description:
          "En Sucosan encontrarás pegamentos para la colocación de cerámica tanto en espacios exteriores como en espacios interiores.",
        seoDescription:
          "En Sucosan ponemos a tu disposición un amplio catálogo de pegamentos para la colocación de cerámica tanto en espacios interiores como en exteriores.",
        ref: "pegamentos",
        options: [
          {
            title: "Ver catálogo del Grupo Puma",
            img: logos.grupoPuma.url,
            alt: "listado de pegamentos de Sucosan en Madrid de la marca Grupo Puma. Consulta su catálogo.",
            redirect: logos.grupoPuma.redirect,
          },
          {
            title: "Ver catálogo de Gecol",
            img: logos.gecol.url,
            alt: "listado de pegamentos de Sucosan en Madrid de la marca Gecol. Consulta su catálogo.",
            redirect: logos.gecol.redirect,
          },
        ],
      },
      {
        title: "Yesos y escayolas",
        seoTitle: "Catálogo | Construcción | Yesos y escayolas Madrid",
        description:
          "Los yesos y las escayolas son derivados del yeso mineral. En nuestro depósito de Madrid tenemos diferentes tipos, así como herramientas para aplicarlos de manera manual o proyectado.",
        seoDescription:
          "Descubre toda nuestra gama de yesos y escayolas para la construcción y reforma. Además, disponemos de herramientas para su aplicación manual y automática.",
        ref: "yesos-escayolas",
        options: [
          {
            title: "Ver catálogo de Hermanos Castaño",
            img: logos.castanho.url,
            alt: "listado de yesos y escayolas de Sucosan en Madrid de la marca Fábrica de Yesos Hermanos Castaño. Consulta su catálogo.",
            redirect: logos.castanho.redirect,
          },
        ],
      },
      {
        title: "Ladrillos y tejas",
        seoTitle: "Catálogo | Construcción | Ladrillos y tejas Madrid",
        description:
          "Las tejas y los ladrillos son dos de los materiales más importantes en la construcción de una casa. Conscientes de ello, en Sucosan ponemos a tu disposición un gran abanico de estos productos, tanto en formas como en tamaños.",
        seoDescription:
          "Sucosan pone a tu disposición un amplio surtido de ladrillos y tejas para la construcción, la pequeña obra y la reforma en Madrid con la posibilidad de hacer envíos a toda la Península.",
        ref: "ladrillos-tejas",
        options: [
          {
            title: "Ver catálogo de Cerasa",
            img: logos.cerasa.url,
            alt: "listado de ladrillos y tejas de Sucosan en Madrid de la marca Cerasa. Consulta su catálogo.",
            redirect: logos.cerasa.redirect,
          },
          {
            title: "Ver catálogo de Tejas Borja",
            img: logos.tejasBorja.url,
            alt: "listado de ladrillos y tejas de Sucosan en Madrid de la marca Tejas Borja. Consulta su catálogo.",
            redirect: logos.tejasBorja.redirect,
          },
        ],
      },
      {
        title: "Áridos",
        seoTitle: "Catálogo | Construcción | Áridos Madrid",
        description:
          "Los áridos son de vital importancia, entre otras cosas, para la colocación de morteros y hormigones. En Sucosan contamos con una variedad de áridos que incluyen arena de río, grava, miga, fina y muchos más.",
        seoDescription:
          "Sucosan contamos con una variedad de áridos que incluyen arena de río, grava, miga, fina y muchos más, todos ellos de las mejores calidades.",
        ref: "aridos",
        options: [
          {
            title: "Ver catálogo de Depiedra",
            img: logos.dePiedra.url,
            alt: "listado de áridos para la construcción de Sucosan en Madrid de la marca Depiedra. Consulta su catálogo.",
            redirect: logos.dePiedra.redirect,
          },
        ],
      },
      {
        title: "Prefabricados de hormigón",
        seoTitle: "Catálogo | Construcción | Prefabricados de hormigón Madrid",
        description:
          "Nuestros proveedores de materiales prefabricados son especialistas en la fabricación de productos de hormigón estructurales y ornamentales, así como en mobiliario de jardín.",
        seoDescription:
          "Entre los muchos materiales de construcción que Sucosan pone a tu disposición están los prefabricados de hormigón estructurales, ornamentales y de jardín.",
        ref: "prefabricados-de-hormigon",
        options: [
          {
            title: "Ver catálogo de Prensagra",
            img: logos.prensagra.url,
            alt: "listado de prefabricados de hormigón para la construcción de Sucosan en Madrid de la marca Prensagra. Consulta su catálogo.",
            redirect: logos.prensagra.redirect,
          },
          {
            title: "Ver catálogo de Facosa",
            img: logos.facosa.url,
            alt: "listado de prefabricados de hormigón para la construcción de Sucosan en Madrid de la marca JM Prefabricados Facosa. Consulta su catálogo.",
            redirect: logos.facosa.redirect,
          },
          {
            title: "Ver catálogo de Weser",
            img: logos.weser.url,
            alt: "listado de prefabricados de hormigón para la construcción de Sucosan en Madrid de la marca Weser. Consulta su catálogo.",
            redirect: logos.weser.redirect,
          },
          {
            title: "Ver catálogo de Verniprens",
            img: logos.verniprens.url,
            alt: "listado de prefabricados de hormigón para la construcción de Sucosan en Madrid de la marca Verniprens. Consulta su catálogo.",
            redirect: logos.verniprens.redirect,
          },
        ],
      },
      {
        title: "Forjados y vigas",
        seoTitle: "Catálogo | Construcción | Forjados y vigas Madrid",
        description:
          "En Sucosan disponemos de diferentes tipos de vigas: semi-resistentes y auto-resistentes. Además, aquí encontrarás lo necesario para realizar forjados con todas las fichas técnicas y documentación.",
        seoDescription:
          "Los forjados y vigas de Sucosan son de la mejor calidad. Contamos con vigas semi-resistentes y auto-resistentes para que encuentres la que mejor se adapte a tus necesidades. También disponemos de todos los productos necesarios para realizar forjados.",
        ref: "forjados-vigas",
        options: [
          {
            title: "Ver catálogo de Brigón",
            img: logos.brigon.url,
            alt: "listado de forjados y vigas para la construcción de Sucosan en Madrid de la marca Brigón. Consulta su catálogo.",
            redirect: logos.brigon.redirect,
          },
        ],
      },
      {
        title: "Forjados sanitarios",
        seoTitle: "Catálogo | Construcción | Forjados sanitarios Madrid",
        description: `Somos distribuidores del sistema denominado "Caviti", que consta de elementos prefabricados de polipropileno reciclado que se ensamblan entre sí de forma rápida.`,
        seoDescription:
          "Descubre la gran variedad de forjados sanitarios que Sucosan pone a tu disposición. Somos distribuidores del sistema Caviti de prefabricados de polipropileno reciclado.",
        ref: "forjados-sanitarios",
        options: [
          {
            title: "Ver catálogo de Caviti",
            img: logos.caviti.url,
            alt: "listado de forjados sanitarios para la construcción de Sucosan en Madrid del sistema Caviti. Consulta su catálogo.",
            redirect: logos.caviti.redirect,
          },
        ],
      },
      {
        title: "Elementos de construcción",
        seoTitle: "Catálogo | Elementos de construcción Madrid",
        description:
          "Disponemos de amplia gama de canaletas y productos de plástico para la construcción.",
        seoDescription:
          "En Sucosan disponemos de todo tipo de materiales de construcción, como canaletas y productos de plástico para la obra y la reforma en Madrid.",
        ref: "elementos-construccion",
        options: [
          {
            title: "Ver catálogo de Dakota",
            img: logos.dakota.url,
            alt: "listado de canaletas y productos de plástico para la construcción de Sucosan en Madrid de la marca Dakota. Consulta su catálogo.",
            redirect: logos.dakota.redirect,
          },
        ],
      },
      {
        title: "Fundición obra pública",
        seoTitle: "Catálogo | Construcción | Fudopsa",
        description:
          "En Sucosan trabajamos con Fudopsa, una de las empresas más importantes del país en la producción de piezas de fundición para obras públicas.",
        seoDescription:
          "Sucosan cuenta con un amplio catálogo de productos de Fudopsa, que incluyen piezas de fundición para saneamientos, abastecimiento de aguas y todo tipo de obras públicas en Madrid.",
        ref: "fundicion-obra-publica",
        options: [
          {
            title: "Ver catálogo de Fudopsa",
            img: logos.fudopsa.url,
            alt: "Fudopsa es uno de los principales productores de piezas para fundición de obras públicas.",
            redirect: logos.fudopsa.redirect,
          },
        ],
      },
    ],
    directSubmenu: [],
  },
  {
    title: "Aislamientos, impermeabilización y cubiertas",
    seoTitle: "Catálogo | Aislamientos, impermeabilización y cubiertas Madrid",
    description:
      "Utilizar los materiales adecuados es un asunto capital para la construcción de espacios seguros y confortables. Los aislamientos, la impermeabilización y las cubiertas son buen ejemplo de ello. Por eso, en Sucosan contamos con las mejores marcas para ofrecerte materiales seguros y efectivos que contribuyen a mejorar la calidad de las construcciones y remodelaciones interiores.",
    seoDescription:
      "Productos específicos para el aislamiento acústico y térmico de una vivienda o local, materiales para la impermeabilización y todo tipo de cubiertas para la obra y reforma en Madrid.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1622654983/sucosan/aislamientos-impermeabilizacion-cubiertas-sucosan_pyanug.jpg",
    seoAltTextImage:
      "productos para aislamiento térmico y aislamiento acústico de viviendas, así como impermeabilización de cubiertas",
    ref: "aislamientos-impermeabilizacion-cubiertas",
    menu: [
      {
        title: "Acústicos",
        seoTitle: "Catálogo | Aislamientos acústicos Madrid",
        description:
          "Sucosan pone a tu disposición los mejores aislamientos acústicos del mercado para insonorizar tu vivienda.",
        seoDescription:
          "En Sucosan disponemos de los mejores materiales para lograr un verdadero aislamiento acústico en tu vivienda o local.",
        ref: "acusticos",
        options: [
          {
            title: "Ver catálogo de Danosa",
            img: logos.danosa.url,
            alt: "Danosa es uno de los proveedores de materiales para el aislamiento térmico y acústico que encontrarás en Sucosan, Madrid",
            redirect: logos.danosa.redirect,
          },
          {
            title: "Ver catálogo de Chova",
            img: logos.chova.url,
            alt: "Sucosan trabaja con Chova, una de las empresas más punteras en aislamientos térmicos y acústicos en Madrid",
            redirect: logos.chova.redirect,
          },
        ],
      },
      {
        title: "Térmicos",
        seoTitle: "Catálogo | Aislamientos térmicos Madrid",
        description:
          "Nuestro catálogo incluye una gran variedad de productos de alto rendimiento para el aislamiento térmico de paredes y techos con algunas de las marcas más punteras del mercado, como Tripomant.",
        seoDescription:
          "Los aislamientos térmicos son fundamentales para la optimización energética de una vivienda. Por ello, en Sucosan trabajamos con marcas como Tripomant, que cuentan con los mejores aislamientos térmicos para paredes y techos.",
        ref: "termicos",
        options: [
          {
            title: "Ver catálogo de Danosa",
            img: logos.danosa.url,
            alt: "En Sucosan trabajamos con Danosa en el suministro de aislamientos térmicos para la vivienda",
            redirect: logos.danosa.redirect,
          },
          {
            title: "Ver catálogo de Chova",
            img: logos.chova.url,
            alt: "Sucosan pone a tu disposición los productos de Chova especializados en aislamientos térmicos",
            redirect: logos.chova.redirect,
          },
          {
            title: "Ver catálogo de Tripomant",
            img: logos.tripomant.url,
            alt: "Materiales y productos de Tripomant para el aislamiento térmico de las viviendas en Madrid",
            redirect: logos.tripomant.redirect,
          },
        ],
      },
      {
        title: "Asfaltos",
        seoTitle: "Catálogo | Aislamientos asfaltos Madrid",
        description:
          "En Sucosan encontrarás telas asfálticas para la impermeabilización de cubiertas de alta gama.",
        seoDescription:
          "Sucosan pone a tu disposición las mejores telas asfálticas para la impermeabilización de cubiertas.",
        ref: "asfaltos",
        options: [
          {
            title: "Ver catálogo de Danosa",
            img: logos.danosa.url,
            alt: "Telas asfálticas para la impermeabilización de cubiertas de la marca Danosa",
            redirect: logos.danosa.redirect,
          },
          {
            title: "Ver catálogo de Chova",
            img: logos.chova.url,
            alt: "Las mejores telas asfálticas de Chova las encontrarás en el almacén de Sucosan en Madrid",
            redirect: logos.chova.redirect,
          },
        ],
      },
      {
        title: "Impermeabilizantes",
        seoTitle: "Catálogo | Impermeabilización Madrid",
        description:
          "Descubre nuestros materiales impermeabilizantes, como masillas, siliconas, poliuretanos y polímeros y disfruta de las mejores ofertas y precios en estos productos.",
        seoDescription:
          "En Sucosan disponemos de una amplia gama de productos para la impermeabilización. Desde masillas y siliconas hasta polímeros y poliuretanos",
        ref: "impermeabilizantes",
        options: [
          {
            title: "Ver catálogo de Ceys",
            img: logos.ceys.url,
            alt: "Impermeabilizantes, adhesivos, pegamentos, selladores y siliconas de la marca Ceys en Madrid",
            redirect: logos.ceys.redirect,
          },
          {
            title: "Ver catálogo de Sika",
            img: logos.sika.url,
            alt: "Masillas, adhesivos, poliuretanos y otros productos de Sika para la impermeabilización de cubiertas",
            redirect: logos.sika.redirect,
          },
        ],
      },
      {
        title: "Cubiertas",
        seoTitle: "Catálogo | Construcción | Cubiertas Madrid",
        description:
          "En Sucosan sólo trabajamos con los mejores proveedores del mercado para poner a tu alcance productos de alta calidad para impermeabilizar cubiertas.",
        seoDescription:
          "En Sucosan encontrarás los mejores sistemas de impermeabilización de cubiertas y tejados de la mano de las mejores marcas del sector.",
        ref: "cubiertas",
        options: [
          {
            title: "Ver catálogo de Onduline",
            img: logos.onduline.url,
            alt: "Los productos de Onduline que Sucosan pone a tu disposición son específicos para el aislamiento y la impermeabilización de cubiertas",
            redirect: logos.onduline.redirect,
          },
          {
            title: "Ver catálogo de Danosa",
            img: logos.danosa.url,
            alt: "Productos de Danosa para el ailamiento y la impermeabilización de cubiertas en Madrid.",
            redirect: logos.danosa.redirect,
          },
        ],
      },
    ],
    directSubmenu: [],
  },
  {
    title: "Ferretería",
    seoTitle: "Catálogo | Ferretería Madrid",
    description:
      "En nuestro catálogo de ferretería encontrarás todos los productos que necesites, desde herramientas de construcción manuales y eléctricas hasta discos de corte, brocas y tornillería.",
    seoDescription:
      "Disponemos de todo tipo de productos de ferretería, como herramientas de construcción manuales y eléctricas, discos de corte, brocas, tornillería y mucho más.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1622654984/sucosan/ferreteria-sucosan_l4isnf.jpg",
    seoAltTextImage:
      "Sucosan cuenta con un amplio catálogo de materiales y productos de ferretería en Madrid.",
    ref: "ferreteria",
    menu: [
      {
        title: "Herramientas de construcción manuales",
        seoTitle: "Catálogo | Herramientas de construcción manuales Madrid",
        description:
          "Las herramientas de construcción manuales que encontrarás en este catálogo son de primera calidad. En Sucosan ponemos a tu alcance destornilladores, llanas, hachas, paletas y demás productos para la obra.",
        seoDescription:
          "En Sucosan contamos con un sinfín de herramientas de construcción manuales como destornilladores, hachas, paletas, llanas y demás soluciones para la construcción en Madrid.",
        ref: "herramientas-construccion-manuales",
        options: [
          {
            title: "Ver catálogo de Rubi",
            img: logos.rubi.url,
            alt: "Herramientas de construcción eléctricas de la marca Rubi como herramientas de mezclado, de colocación, de desbaste y pulido, etc.",
            redirect: logos.rubi.redirect,
          },
          {
            title: "Ver catálogo de Bellota",
            img: logos.bellota.url,
            alt: "Todo tipo de herramientas de construcción manuales de la marca Bellota",
            redirect: logos.bellota.redirect,
          },
          {
            title: "Ver catálogo de Rafael Vallés",
            img: logos.valles.url,
            alt: "Productos de Vallés para la construcción como fresadoras o equipos de amasado",
            redirect: logos.valles.redirect,
          },
          {
            title: "Ver catálogo de Leman",
            img: logos.leman.url,
            alt: "Herramientas de construcción manuales de la marca Lehman",
            redirect: logos.leman.redirect,
          },
        ],
      },
      {
        title: "Herramientas de construcción eléctricas",
        seoTitle: "Catálogo | Herramientas de construcción eléctricas Madrid",
        description:
          "En Sucosan priorizamos la calidad por encima de todo. Por ello, te ofrecemos una amplia gama de herramientas de construcción eléctricas de la mano de las mejores marcas.",
        seoDescription:
          "En Sucosan no sólo disponemos de herramientas de construcción manuales, sino que también tenemos herramientas eléctricas, como taladros, lijadoras, mezcladores, cizallas, clavadoras y mucho más.",
        ref: "herramientas-construccion-electricas",
        options: [
          {
            title: "Ver catálogo de Hikoki",
            img: logos.hikoki.url,
            alt: "Productos de Hikoki como brocas para martillos, para mampostería, multicorte, para metal y mucho más",
            redirect: logos.hikoki.redirect,
          },
          {
            title: "Ver catálogo de Stanley",
            img: logos.stanley.url,
            alt: "Sucosan cuenta con herramientas de medición, de nivelación, cúter, destornilladores y más de la marca Stanley",
            redirect: logos.stanley.redirect,
          },
          {
            title: "Ver catálogo de Imer",
            img: logos.imer.url,
            alt: "Productos de Imer Ibérica como hormigoneras, pulverizadoras, cortadoras, etc.",
            redirect: logos.imer.redirect,
          },
        ],
      },
      {
        title: "Discos de corte",
        seoTitle: "Catálogo | Ferretería | Discos de corte Madrid",
        description:
          "Los discos de corte son piezas de gran necesidad en cualquier obra. En Sucosan los tenemos de diferentes materiales: acero, piedra, madera, desbaste, etc.",
        seoDescription:
          "Sucosan cuenta con una gran variedad de discos de corte, tanto de uso doméstico como profesional y aptos para todo tipo de materiales. Infórmate de todos nuestros productos y de cuál es el mejor para ti.",
        ref: "discos-corte",
        options: [
          {
            title: "Ver catálogo de Fredimar",
            img: logos.fredimar.url,
            alt: "Sucosan cuenta con toda la gama de discos de corte diamantados de la marca Fredimar.",
            redirect: logos.fredimar.redirect,
          },
          {
            title: "Ver catálogo de Rubi",
            img: logos.rubi.url,
            alt: "Los discos de corte para la construcción de Rubi son de la mejor calidad.",
            redirect: logos.rubi.redirect,
          },
        ],
      },
      {
        title: "Brocas y tornillería",
        seoTitle: "Catálogo | Ferretería | Brocas y tornillería Madrid",
        description:
          "Aquí encontrarás una amplia gama de brocas de todas las medidas y para todo tipo de materiales. Además, el catálogo de Sucosan se caracteriza por su gran variedad de tornillería a un precio inmejorable.",
        seoDescription:
          "Las brocas y los tornillos son dos de los materiales más utilizados en la construcción. Conscientes de ello, en Sucosan disponemos de uno de los catálogos más amplios en este tipo de productos y además lo hacemos al mejor precio.",
        ref: "brocas-tornilleria",
        options: [
          {
            title: "Ver catálogo de Imcoinsa",
            img: logos.imcoinsa.url,
            alt: "En Sucosan encontrarás todas las bricas y tornillos de la marca Imcoinsa",
            redirect: logos.imcoinsa.redirect,
          },
          {
            title: "Ver catálogo de SWG",
            img: logos.swg.url,
            alt: "Sucosan es distribuidor de las brocas y los tornillos de SWG",
            redirect: logos.swg.redirect,
          },
          {
            title: "Ver catálogo de Rubi",
            img: logos.rubi.url,
            alt: "Rubi cuenta con una amplia variedad de brocas y tornillería que puedes comprar en Sucosan",
            redirect: logos.rubi.redirect,
          },
          {
            title: "Ver catálogo de Fischer",
            img: logos.fischer.url,
            alt: "Brocas y tornillos de la marca Fischer para la obra o reforma de tu vivienda",
            redirect: logos.fischer.redirect,
          },
        ],
      },
    ],
    directSubmenu: [],
  },
  {
    title: "Pinturas y emplastes",
    seoTitle: "Catálogo | Pinturas y emplastes Madrid",
    description:
      "Descubre toda nuestra gama de pinturas y emplastes. En Sucosan solo trabajamos con las mejores empresas del sector, algunas de las cuales cuentan con más de 50 años de experiencia.",
    seoDescription:
      "Todas las pinturas y emplastes que necesitas para realizar tu obra o reforma en Madrid. Pinturas plásticas, esmaltes, emplastes, barnices a base de agua o a base de disolvente y todas las herramientas y accesorios para su aplicación.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1622655755/sucosan/pinturas-emplastes-sucosan_jeakhi.jpg",
    seoAltTextImage:
      "pinturas y emplastes de todo tipo en el almacén de Sucosan en Madrid",
    ref: "pinturas-emplastes",
    menu: [
      {
        title: "Plásticos y esmaltes",
        seoTitle: "Catálogo | Pinturas plásticas y esmaltes Madrid",
        description:
          "Nuestros acabados de pinturas son muy variados: mate, brillo y satinado y válidos para exterior e interior. También disponemos de silicatos y gama para fachadas.",
        seoDescription:
          "En Sucosan contamos con pinturas plásticas y esmaltes de acabados mates, brillo y satinados, además de productos aptos para interiores y para exteriores.",
        ref: "plasticas-esmaltes",
        options: [
          {
            title: "Ver catálogo de Kolmer",
            img: logos.kolmer.url,
            alt: "pinturas plásticas y esmaltes de la marca Kolmer en todo tipo de acabados",
            redirect: logos.kolmer.redirect,
          },
          {
            title: "Ver catálogo de De la Peña",
            img: logos.penha.url,
            alt: "pinturas plásticas y esmaltes de la marca Pinturas de la Peña en todo tipo de acabados",
            redirect: logos.penha.redirect,
          },
        ],
      },
      // {
      //   title: "Máquina tintométrica",
      //   seoTitle:
      //     "Catálogo | Pinturas y emplastes | Máquina tintométrica Madrid",
      //   description:
      //     "Si necesitas un color concreto, lo mejor es que utilices una máquina tintométrica, encargada de crear el color de pintura que elijas en tan solo unos instantes.",
      //   seoDescription:
      //     "Una máquina tintométrica es la encargada de crear cualquier color de pintura que se necesite. En Sucosan trabajamos con Kolmer, que dispone de las mejores máquinas tintométricas del mercado.",
      //   ref: "maquina-tintometrica",
      //   options: [
      //     {
      //       title: "Ver catálogo de Kolmer",
      //       img: logos.kolmer.url,
      //       alt: "Las máquinas tintométricas de Kolmer, las mejores del mercado",
      //       redirect: logos.kolmer.redirect,
      //     },
      //   ],
      // },
      {
        title: "Emplastes",
        seoTitle: "Catálogo | Pinturas | Emplastes Madrid",
        description:
          "Los emplastes son un material muy práctico para reparaciones pequeñas y trabajos de mayor tamaño. En Sucosan ponemos a tu disposición una amplia gama de productos de todos los tamaños y materiales como fibrados, que son aptos tanto para espacios interiores como exteriores.",
        seoDescription:
          "En Sucosan disponemos de un enorme catálogo de emplastes para trabajos menores. Entra aquí y consulta todos los productos que ponemos a tu alcance.",
        ref: "emplastes",
        options: [
          {
            title: "Ver catálogo de Beissier",
            img: logos.beissier.url,
            alt: "En Sucosan vendemos emplastes de la marca Beissier en nuestro almacén de Madrid",
            redirect: logos.beissier.redirect,
          },
        ],
      },
      {
        title: "Rodillos y accesorios",
        seoTitle:
          "Catálogo | Pinturas y emplastes | Rodillos y accesorios Madrid",
        description:
          "Ponemos a tu disposición todos los materiales que puedas necesitar para darle color a tu casa. Entre ellos destaca una gran gama de brochas, rodillos, pinceles, espátulas, papel para el suelo, plásticos, recambios o cintas.",
        seoDescription:
          "En Sucosan te ofrecemos las mejores pinturas, pero también todos los materiales y productos que necesitas para aplicarlas.  Brochas, rodillos, pinceles, espátulas, papel para el suelo, plásticos, recambios o cintas son algunos de ellos.",
        ref: "rodillos-accesorios",
        options: [
          {
            title: "Ver catálogo de Ciret",
            img: logos.ciret.url,
            alt: "Herramientas para la correcta aplicación de pintura de la marca Ciret",
            redirect: logos.ciret.redirect,
          },
        ],
      },
      {
        title: "Tratamientos de madera",
        seoTitle:
          "Catálogo | Pinturas y emplastes | Tratamientos de madera Madrid",
        description:
          "En este catálogo no solo encontrarás pinturas y materiales para su uso y aplicación, también productos indicados para prevenir enfermedades de la madera.",
        seoDescription:
          "La madera natural es un producto delicado que se debe tratar con productos específicos que ayuden a conservarla en perfecto estado a lo largo de los años. En Sucosan encontrarás todo lo necesario para lograrlo.",
        ref: "tratamientos-madera",
        options: [
          {
            title: "Ver catálogo de Beissier",
            img: logos.beissier.url,
            alt: "Productos de Beissier para prevenir enfermedades de la madera",
            redirect: logos.beissier.redirect,
          },
        ],
      },
      {
        title: "Barnices a base de disolvente",
        seoTitle:
          "Catálogo | Pinturas y emplastes | Barnices a base de disolvente Madrid",
        description:
          "Elige entre un enorme abanico de barnices a base de disolvente con diferentes acabados: satinados, mates y brillo y para diferentes usos, como los barnices específicos para yates. Todos ellos garantizan una cobertura óptima tanto en exteriores como en interiores.",
        seoDescription:
          "Descubre los barnices a base de disolvente que tenemos en el almacén de Sucosan en Madrid. Disponemos de acabados mate, brillo y satinados y productos aptos para interiores y exteriores, así como barnices especiales para yates y otras superficies.",
        ref: "barnices-base-disolvente",
        options: [
          {
            title: "Ver catálogo de Kolmer",
            img: logos.kolmer.url,
            alt: "Barnices a base de disolvente de la marca Kolmer en acabados mate, brillo y satinado para interiores y exteriores",
            redirect: logos.kolmer.redirect,
          },
        ],
      },
      {
        title: "Barnices a base de agua",
        seoTitle:
          "Catálogo | Pinturas y emplastes | Barnices a base de agua Madrid",
        description:
          "Nuestros barnices a base de agua no tienen ese olor característico a disolvente y, como no podía ser de otra forma, se ajustan a los más estrictos controles de calidad y normativas pertinentes. Aquí encontrarás barnices con acabado mate y brillo. Entre las ventajas que caracterizan a estos productos están el bajo índice de contaminación y emisiones, que no son inflamables ni auto-combustibles y gozan de una mayor durabilidad en exteriores.",
        seoDescription:
          "Los barnices a base de agua que encontrarás en Sucosan no huelen a disolvente y los tenemos en acabado mate y brillo. Estos productos se caracterizan por su alta durabilidad y su seguridad, ya que no son inflamables.",
        ref: "barnices-base-agua",
        options: [
          {
            title: "Ver catálogo de Cedria",
            img: logos.cedria.url,
            alt: "Barnices a base de agua de la marca Cedria en acabados mate y brillo",
            redirect: logos.cedria.redirect,
          },
        ],
      },
    ],
    directSubmenu: [],
  },
  {
    title: "Aditivos, químicos y siliconas",
    seoTitle: "Catálogo | Aditivos, químicos y siliconas Madrid",
    description:
      "Distribuimos aditivos químicos y siliconas de las mejores fábricas del sector, como son SIKA, IKM y QUIADSA.",
    seoDescription:
      "En Sucosan ponemos a tu alcance un enorme abanico de aditivos químicos para la construcción y siliconas, adhesivos y selladores de la mejor calidad y de las mejores marcas.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1622654984/sucosan/aditivos-quimicos-siliconas-sucosan_cclkop.jpg",
    seoAltTextImage:
      "Los mejores aditivos químicos y siliconas para la obra y reforma",
    ref: "aditivos-quimicos-siliconas",
    menu: [
      {
        title: "Aditivos de construcción",
        seoTitle: "Catálogo | Aditivos de construcción Madrid",
        description:
          "Disponemos de una amplia gama de aditivos para la construcción, como plastificantes, acelerantes, hidrófugos, plastificantes para suelo radiante, retardantes para mortero y un sinfín de productos que puedes consultar en estos catálogos.",
        seoDescription:
          "Sucosan cuenta con un amplio catálogo de aditivos para la construcción en Madrid, tales como plastificantes, acelerantes, hidrófugos, plastificantes para suelo radiante o retardantes.",
        ref: "aditivos-construccion",
        options: [
          {
            title: "Ver catálogo de Sika",
            img: logos.sika.url,
            alt: "Los mejores aditivos químicos para la construcción de la mano Sika",
            redirect: logos.sika.redirect,
          },
          {
            title: "Ver catálogo de IKM",
            img: logos.ikm.url,
            alt: "Aditivos químicos para la construcción de la marca IKM en Sucosan, Madrid",
            redirect: logos.ikm.redirect,
          },
          {
            title: "Ver catálogo de Quiadsa",
            img: logos.quiadsa.url,
            alt: "Sucosan cuenta con los mejores aditivos químicos para la construcción de la marca Quiadsa",
            redirect: logos.quiadsa.redirect,
          },
        ],
      },
      {
        title: "Adhesivos y selladores",
        seoTitle: "Catálogo | Adhesivos y selladores Madrid",
        description:
          "Somos distribuidores de Fischer, líder en sistemas de anclaje y ponemos a tu alcance masillas anti-moho para baños, siliconas, polímeros y poliuretanos.",
        seoDescription:
          "En Sucosan encontrarás adhesivos y selladores como masillas anti-moho para baños, siliconas, polímeros y poliuretanos. Descúbrelos aquí o en nuestro almacén de Madrid.",
        ref: "adhesivos-selladores",
        options: [
          {
            title: "Ver catálogo de Ceys",
            img: logos.ceys.url,
            alt: "En Sucosan tenemos los mejores adhesivos y selladores de la marca Ceys",
            redirect: logos.ceys.redirect,
          },
          {
            title: "Ver catálogo de Sika",
            img: logos.sika.url,
            alt: "Masillas anti-moho para baños, siliconas, polímeros, poliuretanos y más de la marca Sika",
            redirect: logos.sika.redirect,
          },
          {
            title: "Ver catálogo de Fischer",
            img: logos.fischer.url,
            alt: "Sucosan es distribuidor de Fischer, líder en sistemas de anclaje",
            redirect: logos.fischer.redirect,
          },
        ],
      },
    ],
    directSubmenu: [],
  },
  {
    title: "Fila",
    seoTitle: "Catálogo | Sucosan | Productos de Fila",
    description:
      "Fábrica líder en sistemas de limpieza de cerámicas y piedras naturales. En este apartado encontrarás tratamientos para cerámica, barro, porcelánico, anti-grafitis y productos para la prevención de suciedad.",
    seoDescription:
      "Sucosan es distribuidor oficial de Fila, que es la fábrica líder en sistemas de limpieza de cerámicas y piedras naturales. Consulta aquí el catálogo completo.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1622654982/sucosan/fila-sucosan_y81rap.jpg",
    seoAltTextImage:
      "Fila es la fábrica líder en productos para la limpieza de cerámica y piedra.",
    ref: "fila",
    menu: [],
    directSubmenu: [
      {
        title: "Ver catálogo de Fila",
        img: logos.fila.url,
        alt: "Sucosan distribuye productos de Fila como tratamientos para cerámica, barro, porcelánico, anti-grafitis y productos para la prevención de suciedad",
        redirect: logos.fila.redirect,
      },
    ],
  },
  {
    title: "Cartón yeso y perfilería",
    seoTitle: "Catálogo | Cartón yeso y perfilería Madrid",
    description:
      "El cartón yeso es un material de construcción que se utiliza en interiores para la ejecución de tabiques y revestimientos de paredes y techos. En Sucosan encontrarás este material, además de perfilería, que también se utiliza en estas obras.",
    seoDescription:
      "En Sucosan contamos con una gran variedad de cartón yeso y perfilería para espacios interiores especialmente diseñados para la ejecución de tabiques, pareces y techos.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1623865707/sucosan/carton_yeso_2_n8vzf2.jpg",
    seoAltTextImage:
      "Catálogo de cartón yeso para la construcción de tabiques, paredes y techos",
    ref: "carton-yeso-perfileria",
    menu: [],
    directSubmenu: [
      {
        title: "Ver catálogo de Knauf",
        img: logos.knauf.url,
        alt: "Catálogo de cartón yeso para la construcción y perfilería de la marca Knauf en Madrid",
        redirect: logos.knauf.redirect,
      },
      {
        title: "Ver catálogo de Hermanos Castaño",
        img: logos.castanho.url,
        alt: "Catálogo de cartón yeso y perfilería para la construcción de la marca Yesos Hermanos Castaño",
        redirect: logos.castanho.redirect,
      },
    ],
  },
  {
    title: "Fontanería",
    seoTitle: "Catálogo | Fontanería | Madrid",
    description:
      "En Sucosan encontrarás todos los materiales necesarios para llevar a cabo tu obra o reforma, entre los cuales se encuentra una importante gama de productos de fontanería. Contamos con los mejores fabricantes de tubería de PVC, accesorios, latón, masillas, piezas para PVC y un sinfín de productos.",
    seoDescription:
      "En Sucosan ponemos a tu disposición todos los materiales de fontanería que puedas necesitar en tu obra o reforma. Desde tubos de PVC hasta latón, o masillas.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1622654983/sucosan/fontaneria-sucosan_g521to.jpg",
    seoAltTextImage: "Catálogo de productos de fontanería de Sucosan en Madrid",
    ref: "fontaneria",
    menu: [],
    directSubmenu: [
      {
        title: "Ver catálogo de Cosan",
        img: logos.cosan.url,
        alt: "Cosan es una de las mejores marcas de Madrid de productos de fontanería",
        redirect: logos.cosan.redirect,
      },
      {
        title: "(**)Ver catálogo de Elinsa",
        img: logos.elinsa.url,
        alt: "Elinsa es una de las mejores marcas de Madrid de productos de ventilación",
        redirect: logos.elinsa.redirect,
      },
    ],
  },
  {
    title: "Ropa y protección laboral",
    seoTitle: "Catálogo | Ropa y protección laboral en Madrid",
    description:
      "Los Equipos de Protección Individual (EPI), así como cualquier elemento que contribuya a dotar de seguridad y confort a los trabajadores es fundamental. Por ello, disponemos de una amplia gama de modelos de ropa y calzado, así como otros elementos de protección laboral para el profesional.",
    seoDescription:
      "En Sucosan puedes encontrar ropa, calzado y complementos para asegurar la seguridad y la comodidad de los trabajadores de cualquier sector, así como EPI y otros elementos de protección laboral.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1622654984/sucosan/ropa-proteccion-laboral-sucosan_h9ay11.jpg",
    seoAltTextImage:
      "Catálogo de ropa, calzado y complementos de protección laboral en Madrid",
    ref: "ropa-proteccion",
    menu: [
      {
        title: "Calzado",
        seoTitle: "Catálogo |Ropa y protección laboral en Madrid | Calzado",
        description:
          "El calzado de seguridad tiene como objetivo principal la seguridad del trabajador. Aquí encontrarás diferentes tipos de calzado que responden a todas las necesidades de los diferentes profesionales. ",
        seoDescription:
          "Encuentra el calzado de protección más adecuado para ti o tus trabajadores. Consulta nuestro catálogo completo de zapatos de protección laboral de la marca Bellota y Delta Plus.",
        ref: "calzado",
        options: [
          {
            title: "Ver catálogo de Bellota",
            img: logos.bellota.url,
            alt: "Catálogo de calzado y zapatos de protección laboral de la marca Bellota",
            redirect: logos.bellota.redirect,
          },
          {
            title: "Ver catálogo de Delta Plus",
            img: logos.deltaPlus.url,
            alt: "Catálogo de calzado y zapatos de protección laboral de la marca Delta Plus",
            redirect: logos.deltaPlus.redirect,
          },
        ],
      },
      {
        title: "Ropa",
        seoTitle: "Catálogo |Ropa y protección laboral en Madrid | Ropa",
        description:
          "En Sucosan disponemos de un catálogo textil que aglutina pantalones, polos, chalecos y otros tipos de ropa, siempre de las mejores marcas del mercado de la construcción y que garantizan tu seguridad",
        seoDescription:
          "Descubre la mejor ropa laboral para ti o tus trabajadores. Chalecos, pantalones, camisetas, polos y todo tipo de EPI de las marcas Delta Plus y Bellota.",
        ref: "ropa",
        options: [
          {
            title: "Ver catálogo de Bellota",
            img: logos.bellota.url,
            alt: "Bellota es una de las mejores marcas de ropa de protección laboral y epis",
            redirect: logos.bellota.redirect,
          },
          {
            title: "Ver catálogo de Delta Plus",
            img: logos.deltaPlus.url,
            alt: "Catálogo de ropa de protección laboral y epi de la marca Delta Plus en Madrid",
            redirect: logos.deltaPlus.redirect,
          },
        ],
      },
      {
        title: "Complementos",
        seoTitle:
          "Catálogo |Ropa y protección laboral en Madrid | Complementos",
        description:
          "Además de ropa y calzado de protección, en Sucosan encontrarás todo tipo de complementos, como protectores auditivos, guantes, tapones auditivos, rodilleras y mucho más",
        seoDescription:
          "En Sucosan contamos con un gran catálogo de complementos de protección laboral como guantes, tapones o rodilleras, entre otros. Descubre todos los artículos en nuestro catálogo.",
        ref: "complementos",
        options: [
          {
            title: "Ver catálogo de Bellota",
            img: logos.bellota.url,
            alt: "Catálogo de complementos de protección laboral de la marca Bellota en Madrid",
            redirect: logos.bellota.redirect,
          },
          {
            title: "Ver catálogo de Delta Plus",
            img: logos.deltaPlus.url,
            alt: "Catálogo de complementos de protección laboral de la marca Delta Plus en Madrid",
            redirect: logos.deltaPlus.redirect,
          },
          {
            title: "Ver catálogo de Rubi",
            img: logos.rubi.url,
            alt: "Catálogo de complementos de protección laboral de la marca Rubi en Madrid",
            redirect: logos.rubi.redirect,
          },
        ],
      },
    ],
    directSubmenu: [],
  },
  {
    title: "Azulejos y pavimentos",
    seoTitle: "Catálogo |Azulejos y pavimentos en Madrid",
    description:
      "En Sucosan encontrarás azulejos y pavimentos de fabricantes españoles especializados en porcelánicos o rústicos para espacios interiores y espacios exteriores, todos ellos de la mejor calidad.",
    seoDescription:
      "Descubre el catálogo de azulejos y pavimentos de Sucosan en Madrid, donde encontrarás todo tipo de materiales para espacios exteriores e interiores, incluidos porcelánicos y rústicos.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1622654984/sucosan/azulejos-pavimentos-sucosan_hjktd1.jpg",
    seoAltTextImage:
      "catálogo de azulejos y pavimentos para interiores y exteriores en Madrid",
    ref: "azulejos-pavimentos",
    menu: [],
    directSubmenu: [
      {
        title: "Ver catálogo de Argenta",
        img: logos.argenta.url,
        alt: "Comprar azulejos y pavimentos de Argenta en Sucosan, Madrid",
        redirect: logos.argenta.redirect,
      },
      {
        title: "Ver catálogo de Grupo Greco Gres",
        img: logos.grupoGrecoGres.url,
        alt: "Comprar azulejos y pavimentos de Grupo Greco Gres",
        redirect: logos.grupoGrecoGres.redirect,
      },
    ],
  },
  {
    title: "Recogida de escombros",
    seoTitle: "Catálogo | Recogida de escombros en Madrid",
    description:
      "En Sucosan disponemos de sacas y contenedores para la recogida de escombros en la Comunidad de Madrid. Para las pequeñas obras es más habitual el uso de sacas de 1 m3, mientras que las grandes obras suelen requerir de contenedores de 3m3 y de 6m3 . Sea cual sea tu caso, en Sucosan ponemos a tu disposición el material que mejor se adapte a tus necesidades.",
    seoDescription:
      "¿Necesitas ayuda en la recogida de escombros? En Sucosan ponemos a tu disposición sacas y contenedores específicos para la recogida en escombros en la Comunidad de Madrid.",
    image:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1626683715/sucosan/Logos/recogida_de_escombro_tqc6za.jpg",
    seoAltTextImage:
      "Sacos y contenedores para la recogida de escombro en la Comunidad de Madrid",
    ref: "recogida-escombros",
    menu: [],
    directSubmenu: [],
  },
];
