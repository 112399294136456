import { API } from "aws-amplify";

export const sendBudgetEmail = async ({
  name,
  company,
  email,
  products,
  transport,
  address,
}) => {
  try {
    await API.post("emailapi", "/sendBudgetEmail", {
      body: { name, company, email, products, transport, address },
    });
  } catch (error) {
    return "ERROR";
  }
};

export const sendContactEmail = async ({
  subject,
  name,
  company,
  email,
  msg,
}) => {
  try {
    await API.post("emailapi", "/sendContactEmail", {
      body: { subject, name, company, email, msg },
    });
  } catch (error) {
    return "ERROR";
  }
};
