import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./utilities/_global.css";
import { BrowserRouter } from "react-router-dom";
import Amplify from "aws-amplify";
import { Helmet } from "react-helmet";
// import config from './aws-exports'
// Amplify.configure(config)
Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cloud_logic_custom: [
    {
      name: process.env.REACT_APP_AWS_APINAME,
      endpoint: process.env.REACT_APP_AWS_ENDPOINT,
      region: process.env.REACT_APP_AWS_REGION,
    },
  ],
});

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Helmet>
      <title>Sucosan</title>
      <meta
        name="description"
        content="Sucosan, tu tienda de materiales de construcción de la zona centro de Madrid"
      />
      <meta
        name="keywords"
        content="Sucosan,tienda de materiales de construcción, zona centro de Madrid"
      />
      <link
        id="favicon"
        rel="icon"
        href="https://res.cloudinary.com/dt22oxno6/image/upload/v1632245037/sucosan/icono/Flaticon_Sucosan_laaqz9.jpg"
        type="image/x-icon"
      />
    </Helmet>
    <App />
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById("root")
);
