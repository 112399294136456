import React, { useState } from "react";
import MenuStripSubMenu from "./menu-strip-mobile-submenus";

const MenuStripWithArrow = ({ title, linkTo, menu, showArrow }) => {
  const [showFirstSubMenu, setShowFirstSubMenu] = useState(false);

  const getFirstMenu = Object.values(menu).map((opt) => {
    return opt
  });

  const getSecondSubMenu = Object.values(menu).map((opt) => {
    if (opt.menu.length) {
      return opt.menu.map((sub) => {
        return sub;
      });
    }
    return [];
  });

    const HandleshowFirstSubMenu = () => {
    setShowFirstSubMenu(!showFirstSubMenu);
  };

  return (
    <div className="menu-strip-mobile-option">
      <div className="menu-strip-mobile-option-main">
        <a href={linkTo}>{title}</a>
        {showArrow && (
          <>
            {!showFirstSubMenu && (
              <i
                className="fa fa-sort-down"
                onClick={HandleshowFirstSubMenu}
              ></i>
            )}
            {showFirstSubMenu && (
              <i className="fa fa-sort-up" onClick={HandleshowFirstSubMenu}></i>
            )}
          </>
        )}
      </div>

      {/* SUB MENU */}
      {showFirstSubMenu && (
        <div>
          {getFirstMenu.map((el, i) => (
            <MenuStripSubMenu key={el.title} firstMenu={el} secondMenu={getSecondSubMenu} it={i} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuStripWithArrow;



// import React, { useState } from "react";
// import MenuStripSubMenu from "./menu-strip-mobile-submenus";

// const MenuStripWithArrow = ({ title, linkTo, subMenu, showArrow }) => {
//   const [showFirstSubMenu, setShowFirstSubMenu] = useState(false);

//   const splittedMenu = Object.keys(subMenu).map((opt) => {
//     let data = {};
//     data[opt] = subMenu[opt];
//     return data;
//   });

//   const HandleshowFirstSubMenu = () => {
//     setShowFirstSubMenu(!showFirstSubMenu);
//   };

//   return (
//     <div className="menu-strip-mobile-option">
//       <div className="menu-strip-mobile-option-main">
//         <a href={linkTo}>{title}</a>
//         {showArrow && (
//           <>
//             {!showFirstSubMenu && (
//               <i
//                 className="fa fa-sort-down"
//                 onClick={HandleshowFirstSubMenu}
//               ></i>
//             )}
//             {showFirstSubMenu && (
//               <i className="fa fa-sort-up" onClick={HandleshowFirstSubMenu}></i>
//             )}
//           </>
//         )}
//       </div>

//       {/* SUB MENU */}
//       {showFirstSubMenu && (
//         <div>
//           {splittedMenu.map((el, i) => (
//             <MenuStripSubMenu key={i} subMenuObject={el} />
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default MenuStripWithArrow;
