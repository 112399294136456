import React from 'react';
import FooterMobile from './footer-mobile';
import './footer.css'

const Footer = () => {

  return(
    <div className="footer-container">
      <FooterMobile />
    </div>
  )
}

export default Footer