import React from "react";
import "./Brands.css";
import { logos } from "../../utilities/logos";

const Brands = () => {
  return (
    <div className="mobile-body-brands">
      <div className="third-basis">
        <img
          src={logos.grupoPuma.url}
          alt="Productos del Grupo Puma que Sucosan pone a tu disposición, como morteros y adhesivos para la construcción."
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.bellota.url}
          alt="Sucosan cuenta con los mejores productos de Bellota, especialista en herramientas de construcción manuales."
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.rubi.url}
          alt="En Sucosan trabajamos con el mayor fabricante de herramientas para la construcción, Rubi"
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.chova.url}
          alt="En el almacén de Sucosan en Madrid encontrarás los mejores productos de aislamiento e impermeabilización de Chova"
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.danosa.url}
          alt="Descubre todos los productos de Danosa que encontrarás en Sucosan, desde aislamientos acústicos y térmicos hasta asfaltos y cubiertas."
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.cerasa.url}
          alt="Sucosan dispone de ladrillos y tejas de Cerasa, que se caracterizan por su alta calidad."
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.hikoki.url}
          alt="Te mostramos las mejores herramientas de construcción eléctricas de la mano de Hikoki."
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.sika.url}
          alt="Sucosan pone a tu disposición los productos de Sika impermeabilizantes, así como aditivos de construcción, adhesivos y selladores."
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.ceys.url}
          alt="En Sucosan encontrarás impermeabilizantes, adhesivos y selladores de Ceys, muy utilizados en la construcción y la pequeña reforma."
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.kolmer.url}
          alt="En Sucosan contamos con una de las mejores marcas de pinturas y máquinas tintométricas, que es Kolmer."
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.fila.url}
          alt="En Sucosan ponemos a tu disposición los productos de limpieza de cerámicas y piedras de Fila."
        />
      </div>
      <div className="third-basis">
        <img
          src={logos.beissier.url}
          alt="Te mostramos los mejores productos de Beissier como sus emplastes y sus tratamientos para la madera."
        />
      </div>

      <a href="/catalogo" className="mobile-body-button">
        VER CATÁLOGO
      </a>
    </div>
  );
};

export default Brands;
