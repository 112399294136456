import React from "react";
import CarouselMobile from "../carousel/carousel-mobile";
import Brands from "../brands/Brands";
import CircularSkills from "../skills/CircularSkills";

const WebBody = () => {
  return (
    <div className="web-body-container">
      <CarouselMobile />

      <h1 className="web-body-bold-text-main">
        Tu tienda de materiales de construcción de la zona centro de Madrid
      </h1>

      <a href="/presupuesto" className="web-body-button">
        PIDE PRESUPUESTO
      </a>

      <div className="web-body-brands">
        <p className="web-body-bold-text">
          Sucosan está ubicado en la zona del centro de Madrid, aunque
          suministramos materiales de construcción a toda España, salvo las
          islas Canarias y Baleares
        </p>

        <p className="web-body-normal-text">
          Trabajamos con las mejores marcas de los sectores de la construcción y
          de la reforma
        </p>

        <Brands />
      </div>

      <div className="web-body-catalog">
        <p className="web-body-bold-text">
          En Sucosan encontrarás todos los productos necesarios para llevar a
          cabo cualquier tipo de obra o reforma con la mejor calidad y al mejor
          precio
        </p>
        <p className="web-body-normal-text">
          Ponemos a tu disposición un catálogo de cientos de productos de
          construcción, aislamientos, impermeabilización, ferretería,
          fontanería, pintura, azulejos, pavimentos y mucho más
        </p>
        <a href="/catalogo" className="web-body-button">
          VER CATÁLOGO
        </a>
      </div>

      <div className="web-body-skills">
        <p className="web-body-bold-text">
          Sucosan, mucho más que una tienda de materiales de construcción en
          Madrid
        </p>
        <p className="web-body-normal-text">
          Nos caracterizamos por ofrecer a nuestros clientes un trato
          personalizado y un servicio excelente
        </p>

        <CircularSkills />
      </div>

      <div className="web-body-catalog">
        <p className="web-body-normal-text-map">
          Te ofrecemos transporte personalizado o, si lo prefieres, puedes venir
          a recogerlo a nuestro almacén de construcción en la Calle Sandoval,
          20, en pleno centro de Madrid
        </p>
        <iframe
          title="web-map"
          className="web-body-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.030213306304!2d-3.7072493846036574!3d40.430329879363605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422861ac1691dd%3A0xe7fab9d4640210f7!2sCalle%20de%20Sandoval%2C%2020%2C%2028010%20Madrid!5e0!3m2!1ses!2ses!4v1617089897412!5m2!1ses!2ses"
        ></iframe>
        <a href="/contacto" className="web-body-button">
          CONTACTO
        </a>
      </div>
    </div>
  );
};

export default WebBody;
