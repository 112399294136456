import React, { useState } from "react";
import "./contact.css";
import { sendContactEmail } from "../../services/sucosan-service";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [data, setData] = useState({
    subject: "",
    name: "",
    company: "",
    email: "",
    msg: "",
  });

  const [emailInfo, setEmailInfo] = useState({
    sent: false,
    error: false,
    sending: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailInfo({ ...data, sending: true });
    sendContactEmail(data)
      .then((result) => {
        setData({
          subject: "",
          name: "",
          company: "",
          email: "",
          msg: "",
        });
        setEmailInfo({ sent: true, error: false, sending: false });
        if (result === "ERROR") {
          setEmailInfo({ sent: false, error: true, sending: false });
        }
      })
      .catch((error) =>
        setEmailInfo({ sent: false, error: true, sending: false })
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  return (
    <div className="contact-container">
      <Helmet>
        <title>Contacto Sucosan | Materiales de construcción en Madrid</title>
        <meta
          name="description"
          content="Contacta con el equipo de expertos de Sucosan, el almacén de materiales de construcción de la zona centro de Madrid. Repartimos en toda la geografía peninsular. Estamos a tu disposición por teléfono, email o en nuestras instalaciones."
        />
      </Helmet>
      <div className="contact-container-info">
        <h2 className="contact-title">Contacto</h2>
        {emailInfo.sent && (
          <p className="contact-email-sent">Solicitud de contacto enviada!</p>
        )}
        {emailInfo.error && (
          <p className="contact-email-sent-error">
            Hubo un error al enviar el correo. Intentarlo más tarde.
          </p>
        )}
        {emailInfo.sending && (
          <p className="contact-email-sending">Enviando el correo...</p>
        )}
        <form
          onSubmit={handleSubmit}
          className="contact-form-container"
          autoComplete="off"
        >
          <label className="contact-form-input-container">
            <span className="contact-form-title">Asunto</span>
            <input
              id="subject"
              className="contact-form-data"
              type="text"
              name="subject"
              value={data.subject}
              onChange={handleChange}
              placeholder="Indica el asunto que quieres tratar con nuestro equipo"
              required={true}
            />
          </label>

          <label className="contact-form-input-container small-input">
            <span className="contact-form-title">Nombre</span>
            <input
              id="name"
              className="contact-form-data"
              type="text"
              name="name"
              value={data.name}
              onChange={handleChange}
              placeholder="Escribe tu nombre completo"
              required={true}
            />
          </label>

          <label className="contact-form-input-container small-input">
            <span className="contact-form-title">Empresa</span>
            <input
              id="company"
              className="contact-form-data"
              type="text"
              name="company"
              value={data.company}
              onChange={handleChange}
              placeholder="Indica el nombre de tu empresa"
            />
          </label>

          <label className="contact-form-input-container">
            <span className="contact-form-title">Email</span>
            <input
              id="email"
              className="contact-form-data"
              type="email"
              name="email"
              value={data.email}
              onChange={handleChange}
              placeholder="Escribe el correo electrónico al que quieras recibir nuestra respuesta"
              required={true}
            />
          </label>

          <label className="contact-form-input-container">
            <span className="contact-form-title">Mensaje</span>
            <textarea
              id="msg"
              className="contact-form-data"
              name="msg"
              value={data.msg}
              onChange={handleChange}
              placeholder="Cuéntanos en qué podemos ayudarte"
              required={true}
            />
          </label>

          <button className="contact-form-button">Enviar</button>
        </form>

        <div className="contact-schedule-container">
          <div className="contact-schedule-information">
            <h3>HORARIO</h3>
            <p>
              Lunes - Viernes <br />
              <br />
              Mañana: 08 h. - 14 h. <br />
              Tarde: 15 h. - 18 h.
            </p>
            <p>Agosto: cerrado los viernes por la tarde</p>
          </div>
          <div className="contact-contact-info-container">
            <div className="contact-contact-info">
              <h3>LLÁMANOS</h3>
              <a href="tel:646442567">646 442 567</a>
            </div>
            <div className="contact-contact-info">
              <h3>ENVÍANOS UN EMAIL</h3>
              <a href="mailto:websucosan@gmail.com">websucosan@gmail.com</a>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-map-container">
        <h2 className="contact-title">VEN A VERNOS</h2>
        <h3 className="contact-company-data">
          Sucosan Construcciones S.L.
          <br />
          Calle Sandoval, 20, Madrid, 28010
        </h3>

        <iframe
          title="web-map"
          className="contact-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.030213306304!2d-3.7072493846036574!3d40.430329879363605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422861ac1691dd%3A0xe7fab9d4640210f7!2sCalle%20de%20Sandoval%2C%2020%2C%2028010%20Madrid!5e0!3m2!1ses!2ses!4v1617089897412!5m2!1ses!2ses"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
