import React from "react";
import "./coming-soon.css";

const ComingSoon = () => {
  return (
    <div>
      <div className="coming-soon-container">
        <img
          src="https://res.cloudinary.com/dt22oxno6/image/upload/v1632245477/sucosan/tienda_online_sucosan_rlgfep.jpg"
          alt="tienda-online"
        />
        <div className="coming-soon-title">
          <p>
            Estamos construyendo una tienda online única que pronto estará
            disponible.
          </p>
          <p>¡Te esperamos!</p>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
