import Footer from "./components/footer/footer";
import Navbar from "./components/navbar/navbar";
import React, { useLayoutEffect, useState } from "react";
import CookieBot from "react-cookiebot";
import CopyRight from "./components/copyright/copyright";
import Body from "./components/body/body";
import { Switch, Route, Redirect } from "react-router-dom";
import About from "./components/about/about";
import Catalog from "./components/catalog/catalog";
import ComingSoon from "./components/coming-soon/coming-soon";
import CatalogMenu from "./components/catalog/catalog-menu";
import CatalogSubMenu from "./components/catalog/catalog-submenu";
import Contact from "./components/contact/contact";
import Budget from "./components/budget/budget";
import LegalWarning from "./components/legal-warning/legal-warning";
import CookiesPolicy from "./components/cookies-policy/cookies-policy";
import PrivacyPolicy from "./components/privacy-policy/privacy-policy";
import Offers from "./components/offers/offers";
import DetailedOffer from "./components/offers/detailed-offer";

function App() {
  const isMobileVersion = window.screen.width < 767 ? true : false;
  const [mobileVersion, setMobileVersion] = useState(isMobileVersion);

  const domainGroupID = process.env.REACT_APP_COOKIEBOT_ID;
  // const [hasCookieBot, setHasCookieBot] = useState(undefined);

  useLayoutEffect(() => {
    function handleResize() {
      if (window.screen.width < 767) {
        setMobileVersion(true);
      } else {
        setMobileVersion(false);
      }
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="App">
      <CookieBot domainGroupId={domainGroupID} />

      <Navbar isMobileVersion={mobileVersion} />

      <Switch>
        <Route exact path="/">
          <Body isMobileVersion={mobileVersion} />
        </Route>
        <Route exact path="/sobre-sucosan" component={About} />

        <Route path="/catalogo/:menu/:subMenu" component={CatalogSubMenu} />
        <Route path="/catalogo/:menu" component={CatalogMenu} />
        <Route exact path="/catalogo">
          <Catalog isMobileVersion={mobileVersion} />
        </Route>

        <Route exact path="/presupuesto" component={Budget} />
        <Route exact path="/contacto" component={Contact} />
        <Route exact path="/ofertas/:product" component={DetailedOffer} />
        <Route exact path="/ofertas" component={Offers} />
        <Route exact path="/tienda-online" component={ComingSoon} />

        <Route exact path="/aviso-legal" component={LegalWarning} />
        <Route exact path="/politica-cookies" component={CookiesPolicy} />
        <Route exact path="/politica-privacidad" component={PrivacyPolicy} />

        <Redirect to="/" />
      </Switch>

      <Footer />
      <CopyRight isMobileVersion={mobileVersion} />
    </div>
  );
}

export default App;
