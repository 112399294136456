import React, { useState } from 'react';
import MenuStripMobile from "../menu-strip/menu-strip-mobile";

const NavbarMobile = () => {

  const [showMenu, setShowMenu] = useState(false)

  const handleClickMenu = () => {
    setShowMenu(!showMenu)
  }

  return (
    <div className="navbar-mobile-container">
      <div className="navbar-mobile-complete">
        <div
          onClick={handleClickMenu}
          className="navbar-mobile-menu"
        >
          <div className="navbar-mobile-lines">
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
          <div className="navbar-mobile-menu-text"><b>MENÚ</b></div>
        </div>

        <div className="navbar-mobile-buttons">
          <div className="navbar-mobile-call">
            <a href="tel:646442567">Llamar</a>
          </div>
          <div className="navbar-mobile-email">
            <a href="mailto:websucosan@gmail.com">Mail</a>
          </div>
        </div>
      </div>
      {showMenu &&
        <MenuStripMobile />
      }
    </div>
  )
}

export default NavbarMobile