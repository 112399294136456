import React from "react";
import "./catalog.css";
import { catalogInformation } from "../navbar/navbar-data";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Catalog = ({ isMobileVersion }) => {
  const catalogData = Object.values(catalogInformation);

  return (
    <div className="catalog-container">
      <Helmet>
        <title>
          Catálogo de productos | Materiales de construcción en Madrid
        </title>
        <meta
          name="description"
          content="Descubre el catálogo completo de productos y materiales de construcción que encontrarás en Sucosan. Trabajamos con las mejores marcas y ofrecemos servicio de entrega en toda la Península."
        />
      </Helmet>
      <h2 className="catalog-title">Catálogo</h2>
      <p className="catalog-text">
        En Sucosan disponemos de un catálogo de cientos de productos de las
        mejores marcas para que puedas llevar a cabo obras o reformas con todas
        las garantías y las mejores calidades.
      </p>
      <div className="catalog-first-submenu">
        {catalogData.map((option, i) => (
          <Link
            to={"/catalogo/" + option["ref"]}
            className="catalog-first-submenu-option"
            key={option["title"]}
          >
            {!isMobileVersion && (
              <img
                className="catalog-image"
                src={option["image"]}
                alt={option["seoAltTextImage"]}
              />
            )}
            <h3 className="catalog-option-text">{option["title"]}</h3>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Catalog;
