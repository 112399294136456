import React from "react";

const FooterMobile = () => {
  return (
    <div className="footer-mobile-content">
      <div className="footer-mobile-info">
        <h5>ATENCIÓN AL CLIENTE</h5>
        <h6>De lunes a viernes</h6>
        <a href="mailto:websucosan@gmail.com">websucosan@gmail.com</a>
        <a href="tel:646442567">646 442 567</a>
      </div>

      <div className="footer-mobile-info">
        <h5>
          <a
            href="http://maps.google.com/?q=Calle de Sandoval, 20, 28010 Madrid"
            className="link-pattern"
          >
            VEN A VERNOS
          </a>
        </h5>
        <h6>Calle Sandoval, 20 (Madrid)</h6>
        <h6>Horario de mañana: 08 h. - 14 h.</h6>
        <h6>Horario de tarde: 15 h. - 18 h.</h6>
        <h6>Agosto: cerrado los viernes por la tarde</h6>
      </div>

      {/* <div className="footer-mobile-map">
        <iframe title="web-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.030213306304!2d-3.7072493846036574!3d40.430329879363605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422861ac1691dd%3A0xe7fab9d4640210f7!2sCalle%20de%20Sandoval%2C%2020%2C%2028010%20Madrid!5e0!3m2!1ses!2ses!4v1617089897412!5m2!1ses!2ses"></iframe>
      </div> */}

      <div className="footer-mobile-info">
        <h5>MAPA WEB</h5>
        <a href="/">Inicio</a>
        <a href="/sobre-sucosan">Sobre Sucosan</a>
        <a href="/catalogo">Catálogo</a>
        <a href="/presupuesto">Pide presupuesto</a>
        <a href="/contacto">Contacto</a>
        <a href="/tienda-online">Tienda online</a>
      </div>

      <div className="footer-mobile-info">
        <h5>
          <b>TRANSPARENCIA</b>
        </h5>
        <a href="/aviso-legal">Aviso legal</a>
        <a href="/politica-cookies">Política de Cookies</a>
        <a href="/politica-privacidad">Política de Privacidad</a>
      </div>
    </div>
  );
};

export default FooterMobile;
