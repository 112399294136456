export const products = [
  {
    url: "hormigoneras-miral",
    name: "Hormigoneras Miral",
    price: "Desde 367€",
    description: `La hormigonera Imer Ibérica MIRAL es una de las hormigoneras que nos presenta Imer Ibérica dentro de su gama de hormigoneras de corona y piñón. La hormigonera Imer Ibérica MIRAL es un modelo que incluye una cabina hermética con una gran robustez y resistencia. Además, este modelo funciona con un motor eléctrico y tiene su clavija e interruptor de encendido o apagado en una posición que apenas requiere esfuerzo de utilizar, por lo que no requiere de mucho esfuerzo.\n
    También incluye una protección de piñón dentado que engrana sobre la corona dentada, cumpliendo así la Normativa CE.\n
    En Sucosan tenemos en oferta las siguientes hormigoneras Miral:\n
    Miral-B135 por 367€ + IVA\n
    Miral-153, por 399€ + IVA\n
    Miral 170, por 503€ + IVA\n
    Miral-190, por 822€ + IVA`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1678122570/sucosan/Ofertas/hormigonera-imer-iberica-miral_kxixw7.jpg",
    seoTitle:
      "Hormigoneras Imer Ibérica Miral B-135, 153, 170 y 190 | Ofertas materiales de construcción en Madrid",
    seoDescription:
      "En Sucosan disponemos de algunas de las mejores hormigoneras de corona y piñón del mercado. Hablamos de las Imer Ibérica Miral B-135, 153, 170 y 190.",
    seoAltTextImage:
      "Hormigoneras Imer Ibérica Miral B-135, Miral 153, Miral 170 y Miral 190.",
  },
  {
    url: "pastera-galvanizada-100",
    name: "Pastera galvanizada 100 L. de Rafael Vallés",
    price: "38,83€",
    description: `La pastera galvanizada de Rafael Vallés se caracteriza por su gran resistencia y estabilidad en la realización de obras en espacios interiores y exteriores. Tiene capacidad de 100 litros y es ideal para todo tipo de yesos, morteros y similares.`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1664030222/sucosan/Ofertas/Pastera_galvanizada_100_L_ffe9ed.jpg",
    seoTitle:
      "Pastera galvanizada 100 L. de Rafael Vallés | Ofertas materiales de construcción en Madrid",
    seoDescription:
      "Disfruta de esta oferta temporal que pone a tu disposición el almacén de materiales de construcción Sucosan de pastera galvanizada de 100 L. marca Rafael Vallés.",
    seoAltTextImage: "Pastera galvanizada 100 L. de Rafael Vallés",
  },
  {
    url: "pastera-galvanizada-con-soporte",
    name: "Pastera galvanizada con soporte de Rafael Vallés",
    price: "65€",
    description: `La pastera galvanizada con soporte de Rafael Vallés presenta unas medidas de 950 cm. x 455 cm. x 430cm. Además, dispone de soporte para obras en interiores para el amasado de yesos.\n
    Este producto destaca por su gran estabilidad y resistencia a lo que se suma su gran ligereza, lo cual facilita su transporte en obra.`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1664030222/sucosan/Ofertas/Pastera_galvanizada_con_soporte_wt3wgk.jpg",
    seoTitle:
      "Pastera galvanizada con soporte | Ofertas materiales de construcción en Madrid",
    seoDescription:
      "Aprovecha esta oferta de pastera galvanizada con soporte de la marca Rafael Vallés disponible en el almacén de materiales de construcción de Sucosan en Madrid.",
    seoAltTextImage:
      "Pastera galvanizada de Rafael Vallés con soporte y medidas de 950 cm. x 455 cm. x 430cm",
  },
  {
    url: "disco-diamantado-250-maquina-agua",
    name: "Disco diamantado turbo Rubi 250mm para máquina de agua",
    price: "65,61€",
    description: `El disco diamantado turbo cuenta con un diseño especialmente pensado para ingletear baldosas de gres porcelánico. Esta herramienta de 250 mm. Para máquina de agua destaca por su excelente estabilidad y su rapidez de corte.\n
    Este disco de marca Rubi permite un corte preciso sin pletinas. No obstante, el uso de este complemento potencia aún más sus cualidades de precisión.`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1664297789/sucosan/Ofertas/disco-diamantado-turbo-rubi_uscohr.jpg",
    seoTitle:
      "Disco diamantado turbo 250 mm. para máquina de agua | Ofertas materiales de construcción en Madrid",
    seoDescription:
      "En Sucosan disponemos de discos diamantados turbo de 250 mm. en oferta, perfectos para realizar ingletes de gran precisión sin pletinas.",
    seoAltTextImage: "Disco diamantado turbo de 250 mm. para máquinas de agua",
  },
  {
    url: "espuma-fijacion-60-segundos",
    name: "Espuma de fijación en 60 seg. 750ml",
    price: "8,08€",
    description: `La espuma de fijación de Quilosa permite un agarre inicial en tan solo 60 segundos, una unión permanente en 2 horas y un curado total en 24 horas. Además, es apta para una fuerza máxima de 500 kg/dm2.\n
    Su aplicación en materiales ligeros permite la unión en 60 segundos, mientras que en materiales más pesados requiere de 120 segundos.\n
    Cada bote de 750 ml. rinde unos 40 metros lineales y equivale a 25 kg de mortero y a 6 cartuchos de adhesivo.\n
    Se trata de una espuma de fácil uso, pues se aplica de manera sencilla con una pistola PU. Además, logra no distorsionar ni expandir las superficies fijadas, ya que no deforma el material.\n
    Si quieres más información sobre este producto, no te pierdas el vídeo explicativo.`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1664031807/sucosan/Ofertas/espuma-fijacion-60-segundos_e9ff89.jpg",
    videoUrl: "https://www.youtube.com/embed/LLpWgRvpK30",
    seoTitle:
      "Espuma Quilosa de fijación en 60 segundos | Ofertas materiales de construcción en Madrid",
    seoDescription:
      "Aprovecha la oferta limitada de espuma de fijación en 60 segundos de la marca Quilosa en nuestro almacén de materiales de construcción de Madrid. ",
    seoAltTextImage:
      " Bote de 750 ml de espuma de rápida fijación en 60 segundos de la marca Quilosa.",
  },
  {
    url: "espuma-fijacion-placa-750",
    name: "Espuma de fijación Quilosa para placa 750 ml.",
    price: "9,04€",
    description: `La espuma de fijación de Quilosa se caracteriza por mejorar la rapidez de los trabajos, ya que es capaz de unir hasta 100 m2 en tan solo una hora. Este producto está listo para su uso, es decir, no requiere electricidad ni agua.\n
    Otro de sus puntos fuertes es su precisión, ya que logra una nivelación automática de los tableros.\n
    En solo 30 minutos, la espuma de Quilosa logra el rejuntado, el emplastecido y el acabado.\n
    Si quieres más información sobre este producto, no te pierdas el vídeo explicativo.`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1664030222/sucosan/Ofertas/espuma-fijacion-placa-750_dons4p.jpg",
    extraImg: [
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1664033604/sucosan/Ofertas/como-usar-espuma-fijacion-750_n5xqhq.png",
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1664033605/sucosan/Ofertas/comparativa-espuma-fijacion-750_zquqru.png",
    ],
    extraImgAltText: [
      "infografía sobre cómo utilizar correctamente la espuma de fijación para placa de Quilosa en tan solo cuatro pasos.",
      "en esta infografía explicamos las diferencias entre la espuma de fijación de Quilosa y los sistemas tradicionales.",
    ],
    videoUrl: "https://www.youtube.com/embed/1hVRDIF_FSM",
    seoTitle:
      "Espuma de fijación Quilosa para placa 750 ml. | Ofertas materiales de construcción en Madrid",
    seoDescription:
      "Oferta en Sucosan de espuma de fijación Quilosa para placa de 750 ml. Unidades limitadas al stock disponible en nuestro almacén de materiales de construcción.",
    seoAltTextImage:
      "Oferta de bote de espuma de fijación de la marca Quilosa de 750 ml.",
  },
  {
    url: "cortadora-rubi-hit-1200-n",
    name: "Cortadora Rubi HIT 1200 N",
    price: "560,91€",
    description: `La cortadora HIT 1200 N de Rubi es una herramienta robusta y precisa, perfecta para los trabajos más exigentes en baldosas cerámicas (azulejos, gres esmaltado, gres porcelánico, etc.). Disponible también con bolsa para su transporte y/o almacenaje.\n
    Longitud de corte de hasta 120 cm. Potencia máxima de separación de 800 kg. Formato máximo de corte en diagonal de 85 x 85 cm. Espesor de corte de entre 3 y 15 mm. Su peso neto es de 14,2 kg. (15 kg incluyendo la bolsa). Sus dimensiones totales son de 143 x 25 x 14,5 cm.\n
    Cuenta con un separador móvil y un conjunto de correderas con rodamiento lineales para una mayor suavidad y confort durante el rayado. Dispone del sistema Magnet, que permite el bloqueo y desbloqueo del pisador para rayar y separar con una sola mano y una mayor velocidad de corte.\n
    El nuevo diseño mejora la visibilidad de la zona de corte y de rayado. Su base de extrusión de aluminio con estructura cerrada permite una mayor rigidez y capacidad de corte.\n
    La cortadora tiene una mayor resistencia a la flexión gracias a las guías macizas de acero cromadas y rectificadas de 20 mm.\n
    También cuenta con un tope lateral para cortes repetitivos y a 45º.\n
    Está equipada con cuatro brazos suplementarios de aluminio para cerámicas de gran formato y un mango con empuñadura soft-grip para una mayor ergonomía y comodidad.\n
    Amplia gama de rodeles adaptables a cada material (6, 8, 10, 18 y 22 mm.). Incluye rodel de 8 mm.`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1645632930/sucosan/Ofertas/Foto_Rubi_Cortadora_Hit_1200_bqvnek.jpg",
    seoTitle: "Ofertas Sucosan | Cortadora HIT 1200 N de Rubi",
    seoDescription:
      "Cortadora HIT 1200 N de Rubi en oferta hasta agotar existencias. Ideal para cortar baldosas cerámicas de gran formato como azulejos, gres esmaltado y gres porcelánico.",
    seoAltTextImage:
      "Oferta de cortadora HIT 1200 N de Rubi para baldosas cerámicas.",
  },
  {
    url: "taco-quimico-fischer",
    name: "Taco químico de Fischer",
    price: "11,03€",
    description: `El anclaje químico FIS VL de fischer es un anclaje químico de viniléster híbrido con homologación para fijaciones en hormigón comprimido y traccionado y en mampostería.\n
    El FIS VL se trabaja con los componentes de sistema homologados varilla roscada FIS A de fischer y la varilla con roscado interno RG M I de fischer en interior y exterior.\n
    La resistencia a la temperatura del mortero de inyección FIS VL de -40 °C a +120 °C permite un nivel de carga sólido incluso en condiciones de altas temperaturas, aumentando así la flexibilidad del procesamiento.\n
    El tiempo de endurecimiento más corto de la variante FIS VL HIGH SPEED permite un progreso del trabajo ágil incluso a bajas temperaturas.`,
    extraTitle: "Ventajas del Taco químico de Fisher:",
    extraDescription: `El FIS VL está homologado para su empleo en hormigón fisurado y en mampostería, donde alcanza una gran capacidad de carga.\n
    El mortero de inyección a base de resina de viniléster permite el anclaje en perforaciones rellenas de agua en hormigón, garantizando así un progreso del trabajo ágil.\n
    La resistencia a la temperatura del mortero de inyección FIS VL de -40 °C a +120 °C permite un nivel de carga sólido incluso en condiciones de altas temperaturas, aumentando así la flexibilidad del procesamiento.\n
    El FIS VL HIGH SPEED tiene un tiempo de endurecimiento considerablemente más corto que el FIS VL, permitiendo un procesamiento ágil incluso a bajas temperaturas.`,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1640254160/sucosan/Ofertas/Taco_qu%C3%ADmico_Fischer_ecqmgf.png",
    seoTitle: "Ofertas Sucosan | Taco químico de Fischer",
    seoDescription:
      "Compra en oferta el taco químico de Fischer,uno de los mejores anclajes químicos de viniléster para fijaciones en hormigón comprimido y traccionado y en mampostería",
    seoAltTextImage:
      "Oferta de taco químico de vinieléster de la marca Fischer.",
  },
  {
    url: "carro-almacen-rueda-impinchable",
    name: "Carro con rueda impinchable Ayerbe",
    price: "66€",
    description: `El carro Ayerbe se caracteriza por sus ruedas, las cuales son impinchables, su tamaño es de 260 milímetros, tiene llantas metálicas y dispone de rodamientos. Además, este producto que pesa tan solo 10 kilos, cuenta con dos manos y una capacidad máxima de carga de 300 kilos.`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1640254160/sucosan/Ofertas/carro_almacen_rueda_impinchable_ayerbe_ulinvc.png",
    seoTitle: "Ofertas Sucosan | Carro de almacén con rueda impinchable",
    seoDescription:
      "En Sucosan tenemos el carro de almacén con rueda impinchable de Ayerbe en oferta. Solo por tiempo limitado y hasta agotar existencias.",
    seoAltTextImage:
      "Carro para almacén con dos manos y rueda impinchable Ayerbe en oferta.",
  },
  {
    url: "aislamiento-termico-reflectivo-tripomant-air",
    name: "Aislamiento térmico reflectivo Tripomant AIR",
    price: "29,41€",
    description: `M2. Aislamiento térmico reflectivo TRIPOMANT AIR, barrera de vapor y totalmente impermeable al aire y al agua, formado por dos láminas flexibles de aluminio puro protegido contra la oxidación (emisividad de 0,05) y una lámina de burbujas de aire de polietileno, con un espesor total de 5 mm, colocado con fijaciones mecánicas (rastrel), sellado de solape (5 cms.) con cinta adhesiva de aluminio Tripofix, proporcionando una Resistencia Térmica de 1,04 m2K/W con cámara estanca de 2 cm. de espesor, según Norma UNE 16012, incluso p.p. de cortes, medios auxiliares y medidas de seguridad, totalmente colocado según el ETE (Evaluación Técnica Europea) y CTE-HE1.\n
    *Consulta precio de TRIPOMANT GR (GAS RADON)\n
    *Consulta precio de TRIPOMANT PLUS`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1640254160/sucosan/Ofertas/Aislamiento_termico_Tripomant_AIR_knhdxc.png",
    seoTitle: "Ofertas Sucosan | Aislamiento térmico reflectivo Tripomant Air",
    seoDescription:
      "Disfruta del aislamiento térmico reflectivo Tripomant Air en oferta. Solo en Sucosan, por tiempo limitado.",
    seoAltTextImage: "Oferta aislamiento térmico refelectivo Tripomant Air.",
  },
  {
    url: "aislamiento-termico-reflectivo-tripomant-c",
    name: "Aislamiento térmico reflectivo Tripomant C",
    price: "75€",
    description: `M2. Aislamiento térmico reflectivo TRIPOMANT C, barrera de vapor y totalmente impermeable al aire y al agua, formado por cuatro láminas flexibles de aluminio puro protegido contra la oxidación (emisividad de 0,05) y tres laminas de espuma de polietileno (λ=0,012 W/mK), con un espesor total de 4 mm, colocado sobre rastreles con fijaciones mecánicas, sellado de solape (5 cms.) con cinta adhesiva de aluminio Tripofix, proporcionando una Resistencia Térmica de R=1,667 m2K/W con dos cámaras de aires estancas de 2 cms. espesor, según Norma UNE 16012, incluso p.p. de cortes, medios auxiliares y medidas de seguridad, totalmente colocado según el ETE (Evaluación Técnica Europea) y CTE-HE1.\n
    *Consulta precio de TRIPOMANT GR (GAS RADON)\n
    *Consulta precio de TRIPOMANT PLUS`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1640254160/sucosan/Ofertas/Aislamiento_termico_Tripomant_C_tlzhv6.png",
    seoTitle: "Ofertas Sucosan | Aislamiento térmico reflectivo Tripomant C",
    seoDescription:
      "En Sucosan disponemos de las mejores ofertas, como este aislamiento térmico reflectivo Tripomant C. ¡Haz tu pedido!",
    seoAltTextImage:
      "Aislamiento térmico reflectivo Tripomant C en oferta en Sucosan.",
  },
  // {
  //   url: "anticongelante-liquido-morteros-frimor",
  //   name: "Anticongelante líquido para morteros Frimor 1l., 5l. ó 25l",
  //   price: "Desde 2,51€",
  //   description: `Frimor es un aditivo anticongelante líquido para morteros, exento de cloruros. Tiene la capacidad de disminuir el punto de congelación del agua, reduciendo el riesgo de formación de cristales de hielo. Acelera el proceso de fraguado a bajas temperaturas. A su vez mejora las resistencias mecánicas del mortero y aumenta las resistencias al ciclo hielo-deshielo. Destacar que al estar exento de cloruros es anticorrosivo.`,
  //   extraTitle: "",
  //   extraDescription: ``,
  //   img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1640254160/sucosan/Ofertas/Anticongelante_liquido_morteros_Frimor_hhptqw.png",
  //   seoTitle: "Ofertas Sucosan | Anticongelante líquido Frimor",
  //   seoDescription:
  //     "Aprovecha esta oferta de anticongelante líquido para morteros Frimor en formato de 1 litro, 5 litros o 25 litros.",
  //   seoAltTextImage: "Anticongelante líquido para morteros Frimor en oferta.",
  // },
  {
    url: "lasury-dry-indoor",
    name: "Lasury Dry Indoor",
    price: "Desde 16,45€",
    description: `Sistema de protección contra la humedad bajo azulejos para muros y paredes de habitaciones en las que puedan darse problemas de saturación de humedad (duchas, cocinas, etc.) y pisos sin sifón en el interior, ya sean de nuevos o por rehabilitación.\n
    Sus propiedades físicas permiten la protección contra la humedad bajo azulejos y es un tratamiento eficaz en muros y paredes de habitaciones en las que puedan darse problemas de saturación de humedad. Es recomendado utilizarlo en estancias como baños, duchas, cocinas y pisos sin sifón en el interior, ya sean nuevos o para rehabilitación, pero no es adecuado para piscinas, estanques, instalaciones industriales, pisos con sifón o terrazas.`,
    extraTitle: "",
    extraDescription: ``,
    img: "https://res.cloudinary.com/dt22oxno6/image/upload/v1640254160/sucosan/Ofertas/Lasury_dry_indoor_hng6fi.png",
    seoTitle: "Ofertas Sucosan | Lasury Dry Indoor",
    seoDescription:
      "En Sucosan tenemos en oferta Lasury Dry Indoor, el mejor sistema de protección contra la humedad para azulejos de muros y paredes.",
    seoAltTextImage:
      "Lasury Dry Indoor contra la humedad de azulejos en oferta solo en Sucosan",
  },
];
