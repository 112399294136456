import React from "react";
import "./CircularSkills.css";

const CircularSkills = () => {
  return (
    <div className="mobile-body-circular-skills">
      <div className="mobile-body-circular-skills-circle">
        ASESORAMIENTO EXPERTO
      </div>
      <div className="mobile-body-circular-skills-circle">EXPERIENCIA</div>
      <div className="mobile-body-circular-skills-circle">CALIDAD</div>
      <div className="mobile-body-circular-skills-circle">VARIEDAD</div>
      <div className="mobile-body-circular-skills-circle">RAPIDEZ</div>
      <div className="mobile-body-circular-skills-circle">TRANSPORTE</div>
    </div>
   );
};

export default CircularSkills;
