import React, { useEffect } from "react";
import { products } from "./offers-data";
import { useParams, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

const DetailedOffer = () => {
  const { product } = useParams();
  const filteredData = products.filter((p) => p.url === product);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!filteredData.length) {
    return <Redirect to="/ofertas" />;
  }
  const productData = filteredData[0];

  return (
    <div className="offer-detail-container">
      <Helmet>
        <title>{productData.seoTitle}</title>
        <meta name="description" content={productData.seoDescription} />
      </Helmet>
      <h3 className="offer-detail-title">{productData.name}</h3>
      <div className="offer-detail-info-container">
        <p className="offer-detail-text">
          {productData.description.replaceAll("    ", "")}
        </p>
        <div className="offer-detail-img-container">
          <img
            className="offer-detail-img"
            src={productData.img}
            alt={productData.seoAltTextImage}
          />
          <p className="offer-detail-price">{productData.price} + IVA</p>
          <p className="offer-detail-img-text">
            *Oferta disponible hasta fin de existencias
          </p>
        </div>
      </div>

      <div className="offer-detail-button-container">
        <a className="offer-detail-button-budget" href="/presupuesto">
          HACER PEDIDO
        </a>
      </div>

      <div className="offer-detail-extra-container">
        {productData.videoUrl ? (
          <iframe
            className="offer-detail-video"
            title="video"
            src={productData.videoUrl}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : null}
        {productData.extraImg?.length > 0 && (
          <>
            {productData.extraImg.map((img, i) => (
              <img
                className="offer-detail-extra-img"
                src={img}
                alt={productData.extraImgAltText[i]}
              />
            ))}
          </>
        )}
      </div>
      {productData.extraTitle !== "" && (
        <div className="offer-detail-extra-container">
          <h3 className="offer-detail-extra-title">{productData.extraTitle}</h3>
          <p className="offer-detail-extra-text">
            {productData.extraDescription.replaceAll("    ", "")}
          </p>
        </div>
      )}
    </div>
  );
};

export default DetailedOffer;
