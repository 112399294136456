import React, { useState } from "react";
import SecondSubmenu from "./navbar-web-second-menu";
import { Link } from "react-router-dom";


const NavbarWebMenu = ({ title, options, linkTo, isSelected }) => {
  const [showFirstSubmenu, setShowFirstSubmenu] = useState(false);

  const getFirstSubMenu = Object.values(options).map((opt) => {
    return opt;
  });

  const getSecondSubMenu = Object.values(options).map((opt) => {
    if (opt.menu.length) {
      return opt.menu.map(sub => {
        return sub
      })
    }
    return []
  });

  const handleMouseOver = () => {
    if (!showFirstSubmenu) {
      setShowFirstSubmenu(true);
    }
  };

  const handleMouseLeave = () => {
    if (showFirstSubmenu) {
      setShowFirstSubmenu(false);
    }
  };


  return (
    <div
      onMouseLeave={handleMouseLeave}
      className={`navbar-web-menu relative ${
        isSelected ? "navbar-web-menu-selected" : ""
      }`}
    >
      <Link
        to={linkTo}
        className="navbar-web-menu-title"
        onMouseOver={handleMouseOver}
      >
        {title}
      </Link>

      {showFirstSubmenu && (
        <div className="absolute navbar-web-separate-menu">
          {getFirstSubMenu.map((option, i) => (
            <div key={option.title}> 
              <SecondSubmenu firstMenu={option} secondMenu={getSecondSubMenu} it={i} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavbarWebMenu;




// import React, { useState } from "react";
// import SecondSubmenu from "./navbar-web-second-menu";
// import { Link } from "react-router-dom";

// const NavbarWebMenu = ({ title, options, linkTo, isSelected }) => {
//   const [showFirstSubmenu, setShowFirstSubmenu] = useState(false);

//   const getFirstSubMenu = Object.keys(options).map((opt) => {
//     let data = {};
//     data[opt] = options[opt];
//     return Object.keys(data);
//   });

//   const getSecondSubMenu = Object.keys(options).map((opt) => {
//     let data = {};
//     data[opt] = options[opt];
//     return Object.values(data);
//   });

//   const handleMouseOver = () => {
//     if (!showFirstSubmenu) {
//       setShowFirstSubmenu(true);
//     }
//   };

//   const handleMouseLeave = () => {
//     if (showFirstSubmenu) {
//       setShowFirstSubmenu(false);
//     }
//   };

//   return (
//     <div
//       onMouseLeave={handleMouseLeave}
//       className={`navbar-web-menu relative ${
//         isSelected ? "navbar-web-menu-selected" : ""
//       }`}
//     >
//       <Link
//         to={linkTo}
//         className="navbar-web-menu-title"
//         onMouseOver={handleMouseOver}
//       >
//         {title}
//       </Link>

//       {showFirstSubmenu && (
//         <div className="absolute navbar-web-separate-menu">
//           {getFirstSubMenu.map((option, i) => (
//             <div key={option}> 
//               <SecondSubmenu name={option} menu={getSecondSubMenu} it={i} />
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default NavbarWebMenu;
