import React from "react";
import { Helmet } from "react-helmet";
import "./legal-warning.css";

const LegalWarning = () => {
  return (
    <div className="legal-warning-container">
      <Helmet>
        <title>Aviso legal | Sucosan | Comunidad de Madrid</title>
        <meta name="googlebot" content="noindex" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <h2 className="legal-warning-title">Aviso legal</h2>
      <div className="legal-warning-text-container">
        <p className="legal-warning-text">
          Sucosan Construcciones S.L., provisto con CIF B-81629123, dirección
          Calle Sandoval, 20, 28010 de Madrid, no puede asumir ninguna
          responsabilidad derivada del uso incorrecto, inapropiado o ilícito de
          la información aparecida en la página de Internet www.sucosan.com.
        </p>
        <br />
        <p className="legal-warning-text">
          Con los límites establecidos en la ley, Sucosan Construcciones S.L. no
          asume ninguna responsabilidad derivada de la falta de veracidad,
          integridad, actualización y precisión de los datos o informaciones que
          se contienen en sus páginas de Internet. Los contenidos e información
          no vinculan a Sucosan Construcciones S.L. ni constituyen opiniones,
          consejos o asesoramiento legal de ningún tipo pues se trata meramente
          de un servicio ofrecido con carácter informativo y divulgativo.{" "}
        </p>
        <br />
        <p className="legal-warning-text">
          La página de Internet www.sucosan.com puede contener enlaces (links) a
          otras páginas de terceras partes que Sucosan Construcciones S.L. no
          puede controlar.
        </p>
        <br />
        <p className="legal-warning-text">
          Por lo tanto, Sucosan Construcciones S.L. no puede asumir
          responsabilidades por el contenido que pueda aparecer en páginas de
          terceros. Los textos, imágenes, sonidos, animaciones, software y el
          resto de contenidos incluidos en este website son propiedad exclusiva
          de Sucosan Construcciones S.L. o sus licenciantes.
        </p>
        <br />
        <p className="legal-warning-text">
          Cualquier acto de transmisión, distribución, cesión, reproducción,
          almacenamiento o comunicación pública total o parcial, debe contar con
          el consentimiento expreso de Sucosan Construcciones S.L..
        </p>
        <br />
        <p className="legal-warning-text">
          Asimismo, para acceder a algunos de los servicios que Sucosan
          Construcciones S.L. ofrece a través del website deberá proporcionar
          algunos datos de carácter personal. En cumplimiento de lo establecido
          en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo,
          de 27 de abril de 2016, relativo a la protección de las personas
          físicas en lo que respecta al tratamiento de datos personales y a la
          libre circulación de estos datos le informamos que, mediante la
          cumplimentación de los presentes formularios, sus datos personales
          quedarán incorporados y serán tratados en los ficheros de Sucosan
          Construcciones S.L. con el fin de poderle prestar y ofrecer nuestros
          servicios así como para informarle de las mejoras del sitio web.
        </p>
        <br />
        <p className="legal-warning-text">
          Asimismo, le informamos de la posibilidad de que ejerza los derechos
          de acceso, rectificación, cancelación y oposición de sus datos de
          carácter personal, manera gratuita mediante email a
          websucosan@gmail.com o en la dirección Calle Sandoval, 20, 28010 de
          Madrid.
        </p>
      </div>
    </div>
  );
};

export default LegalWarning;
