import React from "react";
import { Helmet } from "react-helmet";
import "./cookies-policy.css";

const CookiesPolicy = () => {
  return (
    <div className="cookies-policy-container">
      <Helmet>
        <title>Política de cookies | Sucosan | Comunidad de Madrid</title>
        <meta name="googlebot" content="noindex" />
        <meta name="robots" content="noindex" />
      </Helmet>

      <h2 className="cookies-policy-title">Política de cookies</h2>

      <div className="cookies-policy-text-container">
        <p className="cookies-policy-text">
          En cumplimiento con lo dispuesto en el artículo 22.2 de la Ley
          34/2002, de 11 de julio, de Servicios de la Sociedad de la Información
          y de Comercio Electrónico, esta página web le informa, en esta
          sección, sobre la política de recogida y tratamiento de cookies.
        </p>
        <br />
        <h3 className="cookies-policy-section-text">¿Qué son las cookies?</h3>
        <p className="cookies-policy-text">
          Una cookie es un fichero que se descarga en su ordenador al acceder a
          determinadas páginas web. Las cookies permiten a una página web, entre
          otras cosas, almacenar y recuperar información sobre los hábitos de
          navegación de un usuario o de su equipo y, dependiendo de la
          información que contengan y de la forma en que utilice su equipo,
          pueden utilizarse para reconocer al usuario.
        </p>
        <br />
        <p className="cookies-policy-text">
          En esta web se utilizan cookies de análisis. Bien tratadas por
          nosotros o por terceros, nos permiten cuantificar el número de
          usuarios y así realizar la medición y análisis estadístico de la
          utilización que hacen los usuarios del servicio ofertado. Para ello se
          analiza su navegación en nuestra página web con el fin de mejorar la
          oferta de productos o servicios que le ofrecemos.
        </p>
      </div>
    </div>
  );
};

export default CookiesPolicy;
