import React, { useState } from "react";
import NavbarWebMenu from "./navbar-web-menu";
import { emptyMenu, catalogInformation } from "./navbar-data";

const NavbarWeb = () => {
  const initialInformation = () => {
    const data = {
      beginning: false,
      about: false,
      catalog: false,
      offers: false,
      budget: false,
      contact: false,
      online: false,
    };
    const currentURL = window.location.href;
    if (currentURL.toString().includes("/sobre-sucosan")) {
      data["about"] = true;
    } else if (currentURL.toString().includes("/catalogo")) {
      data["catalog"] = true;
    } else if (currentURL.toString().includes("/ofertas")) {
      data["offers"] = true;
    } else if (currentURL.toString().includes("/presupuesto")) {
      data["budget"] = true;
    } else if (currentURL.toString().includes("contacto")) {
      data["contact"] = true;
    } else if (currentURL.toString().includes("tienda-online")) {
      data["online"] = true;
    } else {
      data["beginning"] = true;
    }
    return data;
  };

  const [selectInformation, setSelectInformation] = useState(
    initialInformation()
  );

  const updateSelection = (option) => {
    const selection = {
      beginning: false,
      about: false,
      catalog: false,
      offers: false,
      budget: false,
      contact: false,
      online: false,
    };
    selection[option] = true;
    setSelectInformation(selection);
  };

  return (
    <div className="navbar-web-complete">
      <div className="navbar-web">
        <div className="navbar-web-image">
          <a href="/">
            <img
              // src="https://i.ibb.co/3ms6qxy/Logo-Sucosan-v-2-blanco.png"
              src="https://res.cloudinary.com/dt22oxno6/image/upload/v1629908177/sucosan/Logos/Logo_Sucosan_v-2_blanco_fdtpj2.png"
              alt="sucosan-logo"
            />
          </a>
        </div>

        <div className="navbar-web-container">
          <div onClick={() => updateSelection("beginning")}>
            <NavbarWebMenu
              title={"INICIO"}
              options={emptyMenu}
              linkTo={"/"}
              isSelected={selectInformation["beginning"]}
            />
          </div>

          <div onClick={() => updateSelection("about")}>
            <NavbarWebMenu
              title={"SOBRE SUCOSAN"}
              options={emptyMenu}
              linkTo={"/sobre-sucosan"}
              isSelected={selectInformation["about"]}
            />
          </div>

          <div onClick={() => updateSelection("catalog")}>
            <NavbarWebMenu
              title={"CATÁLOGO"}
              options={catalogInformation}
              linkTo={"/catalogo"}
              isSelected={selectInformation["catalog"]}
            />
          </div>

          <div onClick={() => updateSelection("offers")}>
            <NavbarWebMenu
              title={"OFERTAS"}
              options={emptyMenu}
              linkTo={"/ofertas"}
              isSelected={selectInformation["offers"]}
            />
          </div>

          <div onClick={() => updateSelection("budget")}>
            <NavbarWebMenu
              title={"PIDE PRESUPUESTO"}
              options={emptyMenu}
              linkTo={"/presupuesto"}
              isSelected={selectInformation["budget"]}
            />
          </div>

          <div onClick={() => updateSelection("contact")}>
            <NavbarWebMenu
              title={"CONTACTO"}
              options={emptyMenu}
              linkTo={"/contacto"}
              isSelected={selectInformation["contact"]}
            />
          </div>

          <div onClick={() => updateSelection("online")}>
            <NavbarWebMenu
              title={"TIENDA ONLINE"}
              options={emptyMenu}
              linkTo={"/tienda-online"}
              isSelected={selectInformation["online"]}
            />
          </div>
        </div>
      </div>

      <div className="navbar-web-contact-information">
        <div className="navbar-web-contact-information-data">
          <div className="navbar-mobile-email">
            <a href="mailto:websucosan@gmail.com" />
          </div>
          <a href="mailto:websucosan@gmail.com">websucosan@gmail.com</a>
          <div className="navbar-mobile-call">
            <a href="tel:646442567" />
          </div>
          <span>646 442 567</span>
        </div>
      </div>
    </div>
  );
};

export default NavbarWeb;
