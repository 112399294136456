import React from "react";
import "./copyright.css";

const CopyRight = ({ isMobileVersion }) => {
  return (
    <div>
      {isMobileVersion && (
        <div className="mobile-copyright">
          <p>
            &copy; 2021 Sucosan Construcciones S.L. web diseñada por{" "}
            <a href="https://www.agenciaplaymarketing.com">
              Agencia Play Marketing
            </a>
          </p>
        </div>
      )}
      {!isMobileVersion && (
        <div className="web-copyright">
          <p>&copy; 2021 Sucosan Construcciones S.L.</p>
          <p>
            web diseñada por{" "}
            <a href="https://www.agenciaplaymarketing.com">
              Agencia Play Marketing
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default CopyRight;
