export const logos = {
  argenta: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862288/sucosan/Logos/Logotipo_Argenta_mn26eb.png",
    alt: "logo-argenta",
    redirect:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1629909557/sucosan/Catalogos/Argenta/CATALOGO_ARGENTA_ago_21_plcxod.pdf",
  },
  beissier: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862285/sucosan/Logos/Logotipo_Beissier_xytutm.png",
    alt: "logo-beissier",
    redirect:
      "https://www.beissier.es/media/documents/cat_logos_en_pdf/TARIFA_BEISSIER_2021.pdf",
  },
  bellota: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862286/sucosan/Logos/Logotipo_Bellota_heimch.png",
    alt: "logo-bellota",
    redirect: "https://www.bellota.com/descargar-catalogo",
  },
  brigon: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862288/sucosan/Logos/Logotipo_Brig%C3%B3n_iemxky.png",
    alt: "logo-brigon",
    redirect: "",
  },
  caviti: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862285/sucosan/Logos/Logotipo_Caviti_ibj9zm.png",
    alt: "logo-caviti",
    redirect: "https://www.caviti.es/media/archivos/Catalogo-Caviti.pdf",
  },
  cedria: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862285/sucosan/Logos/Logotipo_Cedria_cwfnn3.jpg",
    alt: "logo-cedria",
    redirect: "https://www.cedria.es/DOCUMENTS/FotosOtro/PathPdf_e_1.pdf",
    // "http://www.cedria.es/DOCUMENTS/FotosFCKEditor/file/Cedria Cat%C3%A1logo General 2020(1).pdf",
  },
  cerasa: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862289/sucosan/Logos/Logotipo_Cerasa_vzdbnh.png",
    alt: "logo-cerasa",
    redirect:
      "http://www.ceramicaespiritusanto.es/recursos/documentos/CAT_CERASA2020.pdf",
  },
  ceys: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862288/sucosan/Logos/Logotipo_Ceys_aewgrr.png",
    alt: "logo-ceys",
    redirect:
      "https://mundoceys.com/?gclid=EAIaIQobChMImOKusMrL5wIVDETTCh1lXwB4EAAYASAAEgJCbPD_BwE",
  },
  chova: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862288/sucosan/Logos/Logotipo_Chova_s7pkky.png",
    alt: "logo-chova",
    redirect:
      "http://chova.com/documentacion/catalogos/Catalogo ChovA 2021_compressed.pdf",
  },
  ciret: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862289/sucosan/Logos/Logotipo_Ciret_adhhzz.png",
    alt: "logo-ciret",
    redirect:
      "https://www.ciret.es/fileadmin/user_upload/Ciret-ES/downloads/catalogo-cies-2021.pdf",
  },
  cosan: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862289/sucosan/Logos/Logotipo_Cosan_gtgeoz.png",
    alt: "logo-cosan",
    redirect: "", //"https://cosansl.es/fontaneria/"
  },
  dakota: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862290/sucosan/Logos/Logotipo_Dakota_vjnlab.png",
    alt: "logo-dakota",
    redirect: "https://world.dakota.eu/es/catalogo.php",
  },
  danosa: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862290/sucosan/Logos/Logotipo_Danosa_PNG_laindy.png",
    alt: "logo-danosa",
    redirect:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1626683155/sucosan/Catalogos/Danosa/TARIFA_JULIO_2021_rkyuxi.pdf", // "https://portal.danosa.com/danosa/CMSServlet?root=1Productos&node=lista_prod&lng=1&site=1",
  },
  penha: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862675/sucosan/Logos/Logotipo_De_La_Pe%C3%B1a_rz819x.png",
    alt: "logo-de-la-peña",
    redirect:
      "https://delapenia.com/wp-content/uploads/2021/03/Tarifa-2021-v01.pdf",
  },
  deltaPlus: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862289/sucosan/Logos/Logotipo_Delta_Plus_vnho4s.jpg",
    alt: "logo-delta-plus",
    redirect: "https://www.deltaplus.eu/es_ES/home",
  },
  dePiedra: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1624254449/sucosan/Logos/Logo_DEPIEDRA_500x500_jpg_wshcrh.jpg",
    alt: "logo-depiedra",
    redirect: "https://www.depiedra.es/envasados",
  },
  elinsa: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1651680953/sucosan/Logos/Logotipo_Elinsa_yb9daa.png",
    alt: "logo-elinsa",
    redirect: "http://www.elinsa.net/ventilacionextraccion/",
  },
  fila: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862289/sucosan/Logos/Logotipo_Fila_qcj01t.jpg",
    alt: "logo-fila",
    redirect: "https://www.filasolutions.com/",
  },
  fischer: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862290/sucosan/Logos/Logotipo_Fisher_yvvjud.png",
    alt: "logo-fischer",
    redirect: "https://www.fischer.es/es-es/productos",
  },
  fredimar: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862290/sucosan/Logos/Logotipo_Fredimar_stkcbq.jpg",
    alt: "logo-fredimar",
    redirect: "https://pdfhost.io/v/Impk8Lk5E_Catalogo_Fredimarpdf.pdf", //"https://www.fredimar.com/es/",
  },
  gecol: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862290/sucosan/Logos/Logotipo_Gecol_yiosvs.png",
    alt: "logo-gecol",
    redirect: "https://www.gecol.com/Manual_comercial.pdf",
  },
  grupoPuma: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862290/sucosan/Logos/Logotipo_Grupo_Puma_zfmp1w.png",
    alt: "logo-grupo-puma",
    redirect: "https://www.grupopuma.com/uploads/catalogo-general/es/online/",
  },
  grupoGrecoGres: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1653817500/sucosan/Logos/Logotipo_Grupo_GrecoGres_ffdtw4.png",
    alt: "logo-grupo-greco-gres",
    redirect: "https://klinkergreco.net/",
  },
  castanho: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862290/sucosan/Logos/Logotipo_Hermanos_Casta%C3%B1o_skvimo.jpg",
    alt: "logo-hermanos-castaño",
    redirect: "http://www.yesoscastano.com/Prod1.asp",
  },
  hikoki: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862286/sucosan/Logos/Logotipo_Hikoki_yvmzsq.png",
    alt: "logo-hikoki",
    redirect: "https://hikoki-powertools.es/es/content/11-catalogos",
  },
  ikm: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862286/sucosan/Logos/Logotipo_IKM_ffqe7b.png",
    alt: "logo-ikm",
    redirect: "https://ikminnovaciones.com/categoria-producto/construccion/",
  },
  imcoinsa: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862286/sucosan/Logos/Logotipo_Imcoinsa_PNG_qxtmin.png",
    alt: "logo-imcoinsa",
    redirect:
      "http://www.imcoinsa.com/images/DOC/imcoinsa_catalogo_usuario.pdf",
  },
  imer: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862286/sucosan/Logos/Logotipo_Imer_Ib%C3%A9rica_cqokiz.png",
    alt: "logo-imer-iberica",
    redirect: "https://www.imeriberica.com/",
  },
  facosa: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862287/sucosan/Logos/Logotipo_JM_Prefabricados_Facosa_b3dnjp.bmp",
    alt: "logo-jm-prefabricados-facosa",
    redirect: "https://juliochafer.com/index/inicio/facosa/",
  },
  fudopsa: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1678121435/sucosan/Logos/Logotipo_Fudopsa_zv7yuq.jpg",
    alt: "Fudopsa es uno de los principales productores de piezas para fundición de obras públicas.",
    redirect: "https://www.sifsa-fudopsa.com/productos.html",
  },
  knauf: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862286/sucosan/Logos/Logotipo_Knauf_uyxoat.png",
    alt: "logo-knauf",
    redirect: "", //"https://www.knauf.es/productos",
  },
  kolmer: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623864236/sucosan/Logos/Logotipo_Kolmer_PNG_lfmzyi.png",
    alt: "logo-kolmer",
    redirect:
      "https://pdfhost.io/v/tavSDGLfs_TARIFA_KOLMER_2021_TARIFAKOLMER_210601_compressed",
  },
  leman: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862287/sucosan/Logos/Logotipo_Leman_Azul_znjddt.jpg",
    alt: "logo-leman",
    redirect: "https://leman-sa.fr/catalogos/?lang=es",
  },
  onduline: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862287/sucosan/Logos/Logotipo_Onduline_qd82ej.png",
    alt: "logo-onduline",
    redirect:
      "https://es.onduline.com/es/profesionales/herramientas-onduline/catalogos",
  },
  prensagra: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862287/sucosan/Logos/Logotipo_Prensagra_nyos7y.png",
    alt: "logo-prensagra",
    redirect: "",
  },
  quiadsa: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1626676304/sucosan/Logos/QUIADSA_NUEVO_nncttl.jpg",
    alt: "logo-quiadsa",
    redirect:
      "https://drive.google.com/file/d/1lEapnAY-jk_bBpqX9YD0Z4PMtG58Ccya/view",
  },
  rubi: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862288/sucosan/Logos/Logotipo_Rubi_hwagt2.png",
    alt: "logo-rubi",
    redirect: "https://www.rubi.com/es/catalogo",
  },
  sika: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862288/sucosan/Logos/Logotipo_Sika_JPG_uepobv.jpg",
    alt: "logo-sika",
    redirect:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1631124121/sucosan/Catalogos/Sika/Cat%C3%A1logo_Sika_2020_e8dywq.pdf",
  },
  stanley: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862288/sucosan/Logos/Logotipo_Stanley_ailflz.jpg",
    alt: "logo-stanley",
    redirect: "https://www.stanleyworks.es/downloads",
  },
  swg: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1626675943/sucosan/Logos/Logotipo_SWG_hrodyt.png",
    alt: "logo-swg",
    redirect: "",
  },
  tejasBorja: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862288/sucosan/Logos/Logotipo_Tejas_Borja_kbsn7s.jpg",
    alt: "logo-tejas-borja",
    redirect: "https://tejasborja.com/catalogos/",
  },
  tripomant: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1624254454/sucosan/Logos/Logotipo_Tripomant_szyz1w.jpg",
    alt: "logo-tripomant",
    redirect: "https://www.tripomant.com/catalogo/",
  },
  valles: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862289/sucosan/Logos/Logotipo_Vall%C3%A9s_PNG_kmjswx.png",
    alt: "logo-valles",
    redirect:
      "https://res.cloudinary.com/dt22oxno6/image/upload/v1626763591/sucosan/Catalogos/Valles/Cat%C3%A1logo_Rafael_Valles_weopzq.pdf",
  },
  verniprens: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862289/sucosan/Logos/Logotipo_Verniprens_zlap6m.png",
    alt: "logo-verniprens",
    redirect: "https://www.verniprens.com/descargas/",
  },
  weser: {
    url: "https://res.cloudinary.com/dt22oxno6/image/upload/v1623862289/sucosan/Logos/Logotipo_Weser_tpaedi.jpg",
    alt: "logo-weser",
    redirect: "",
  },
};
