import React, { useEffect } from "react";
import "./catalog-submenu.css";
import { catalogInformation } from "../navbar/navbar-data";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

const CatalogSubMenu = (props) => {
  const menu = props.match.params.menu;
  const subMenu = props.match.params.subMenu;

  const getData = () => {
    const data = Object.values(catalogInformation).filter(
      (opt) => opt["ref"] === menu
    );

    if (data.length) {
      const menuTitle = data[0].title;
      if (data[0].menu.length) {
        const subMenuOption = Object.values(data[0].menu).filter(
          (sub) => sub.ref === subMenu
        );
        if (subMenuOption.length) {
          const submenuTitle = subMenuOption[0].title;
          const seoTitle = subMenuOption[0].seoTitle;
          const description = subMenuOption[0].description;
          const seoDescription = subMenuOption[0].seoDescription;
          const options = subMenuOption[0].options;

          return {
            title: (
              <h2>
                {menuTitle} <i className="fa fa-chevron-right"></i>{" "}
                {submenuTitle}
              </h2>
            ),
            seoTitle: seoTitle,
            description: description,
            seoDescription: seoDescription,
            options: options,
          };
        }
      }
    }
    return <Redirect to="/catalogo" />;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = getData();

  return (
    <div className="catalog-submenu-container">
      <Helmet>
        <title>{data["seoTitle"]}</title>
        <meta name="description" content={data["seoDescription"]} />
      </Helmet>
      <div className="catalog-submenu-title">{data["title"]}</div>
      <p className="catalog-submenu-description">{data["description"]}</p>

      <div className="catalog-submenu-options-container">
        {data["options"].map((opt, i) => {
          return (
            <>
              {opt.redirect && (
                <a
                  href={opt.redirect}
                  className="catalog-submenu-option"
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="catalog-submenu-option-img-container">
                    <img src={opt.img} alt={opt.alt} />
                  </div>
                  <p>{opt.title}</p>
                </a>
              )}
              {!opt.redirect && (
                <div className="catalog-submenu-option" key={i + 1}>
                  <div className="catalog-submenu-option-img-container">
                    <img src={opt.img} alt={opt.alt} />
                  </div>
                  <p>{opt.title}</p>
                </div>
              )}
            </>
          );
        })}
      </div>

      <div className="catalog-submenu-buttons-container">
        <a className="catalog-submenu-button" href="/presupuesto">
          PEDIR PRESUPUESTO
        </a>
      </div>
      <p className="catalog-submenu-prices">
        *Los precios que aparecen en cada catálogo no son los precios finales,
        sino orientativos. Para conocer el precio definitivo, por favor,{" "}
        <a href="/presupuesto">contacta con nuestro equipo</a>
      </p>
    </div>
  );
};

export default CatalogSubMenu;
