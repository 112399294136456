import React, { useState } from "react";

const SecondSubmenu = ({ firstMenu, secondMenu, it }) => {
  const [showSecondSubmenu, setShowSecondSubmenu] = useState(false);

  const handleMouseOver = () => {
    if (!showSecondSubmenu) {
      setShowSecondSubmenu(true);
    }
  };

  const handleMouseLeave = () => {
    if (showSecondSubmenu) {
      setShowSecondSubmenu(false);
    }
  };

  return (
    <div
      className="navbar-web-first-submenu"
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
    >
      <ul className="relative navbar-web-first-submenu-option">
        <a href={`/catalogo/` + firstMenu.ref}>
          {firstMenu.title}
        </a>
        {showSecondSubmenu && (
          <ul className="absolute navbar-web-second-submenu second-submenu-right">
            {secondMenu[it].map((secondOption, j) => (
              <div
                key={secondOption.title}
                className="navbar-web-second-submenu-option"
              >
                <a href={`/catalogo/` + firstMenu.ref + `/` + secondOption.ref}>
                  <li>{secondOption.title}</li>
                </a>
                <div className="divider" />
              </div>
            ))}
          </ul>
        )}
      </ul>
      <div className="divider" />
    </div>
  );
};

export default SecondSubmenu;

// import React, { useState } from "react";

// const SecondSubmenu = ({ name, menu, it }) => {
//   const [showSecondSubmenu, setShowSecondSubmenu] = useState(false);

//   const handleMouseOver = () => {
//     if (!showSecondSubmenu) {
//       setShowSecondSubmenu(true);
//     }
//   };

//   const handleMouseLeave = () => {
//     if (showSecondSubmenu) {
//       setShowSecondSubmenu(false);
//     }
//   };

//   return (
//     <div
//       className="navbar-web-first-submenu"
//       onMouseLeave={handleMouseLeave}
//       onMouseOver={handleMouseOver}
//     >
//       <ul className="relative navbar-web-first-submenu-option">
//         <li>{name}</li>
//         {showSecondSubmenu && (
//           <ul className="absolute navbar-web-second-submenu second-submenu-right">
//             {menu[it][0].map((secondOption, j) => (
//               <div
//                 key={secondOption}
//                 className="navbar-web-second-submenu-option"
//               >
//                 <li>{secondOption}</li>
//                 <div className="divider" />
//               </div>
//             ))}
//           </ul>
//         )}
//       </ul>
//       <div className="divider" />
//     </div>
//   );
// };

// export default SecondSubmenu;
