import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";
import { logos } from "../../utilities/logos";

const CarouselMobile = () => {
  return (
    <div className="carousel-container">
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        interval="5000"
        showStatus={false}
        showThumbs={false}
        showArrows={true}
        dynamicHeight={true}
      >
        {/* SLIDE 0 */}
        {/* <div>
          <a href="/presupuesto">
            <div>
              <img
                src="https://res.cloudinary.com/dt22oxno6/image/upload/v1636648509/sucosan/Ofertas/oferta_lasur_dry_indoor_2_anyjdx.png"
                alt="oferta_lasur_dry_indoor"
              />
            </div>
          </a>

          <div className="carousel-mobile-img-button">
            <span className="fa fa-chevron-right"></span>
            <a href="/presupuesto">Haz tu pedido</a>
          </div>
        </div> */}

        {/* SLIDE 0 */}
        <div>
          <a href="/presupuesto">
            <div>
              <img
                src="https://res.cloudinary.com/dt22oxno6/image/upload/v1678375556/sucosan/Slides/Slide-home-cortadoras-azulejos-rubi_qybbif.jpg"
                alt="Cortadora de azulejos."
              />
            </div>
          </a>
          <div className="carousel-mobile-img-text">
            <p>
              Máquinas cortadoras de azulejos de la marca Rubi
            </p>
            <a href="/presupuesto">Haz tu pedido</a>
          </div>
        </div>

        {/* SLIDE 1 */}
        <div>
          <a href="/presupuesto">
            <div>
              <img
                src="https://res.cloudinary.com/dt22oxno6/image/upload/v1658577414/sucosan/Slides/slide-oferta-discos-de-corte-01_ztoy3o.jpg"
                alt="Discos de corte."
              />
            </div>
          </a>
          <div className="carousel-mobile-img-text">
            <p>
              Haz tu pedido o solicita información{" "}
              <a href="/presupuesto">aquí.</a>{" "}
            </p>
            <p>Oferta válida hasta fin de existencias.</p>
          </div>
        </div>

        {/* SLIDE 1 */}
        {/* <div>
          <a
            target="_blank"
            rel="noopener noreferrer "
            href="/catalogo/pinturas-emplastes/maquina-tintometrica"
          >
            <div>
              <img
                src="https://res.cloudinary.com/dt22oxno6/image/upload/v1651679272/sucosan/Slides/Slide-home-maquina-tintometrica-02_pk73vd.jpg"
                alt="La mejor maquina tintometrica del mercado, que permite la creación de cualquier color de pintura que necesites."
              />
            </div>
          </a>
          <div className="carousel-mobile-img-text">
            <p>
              Descuento del 50% en toda la carta de colores plásticas y esmaltes
              hasta agotar existencias.
            </p>
            <a href="/presupuesto">Pide presupuesto</a>
          </div>
        </div> */}

        {/* SLIDE 1 */}
        {/* <div>
          <a href="/presupuesto">
            <div>
              <img
                src="https://res.cloudinary.com/dt22oxno6/image/upload/v1651680177/sucosan/Slides/slide-martillos-y-multiherramienta-01_jdtndn.jpg"
                alt="Martillos perforadores."
              />
            </div>
          </a>
          <div className="carousel-mobile-img-text">
            <p>
              Haz tu pedido o solicita información{" "}
              <a href="/presupuesto">aquí.</a>{" "}
            </p>
            <p>Oferta válida hasta fin de existencias.</p>
          </div>
        </div> */}

        {/* SLIDE 2 */}
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer "
            href={logos.rubi.redirect}
          >
            <div>
              <img
                src="https://res.cloudinary.com/dt22oxno6/image/upload/v1631293730/sucosan/Slides/Slide_01_batidora_rubimix_supertorque_a1rfd6.png"
                alt="La batidora Rubimix de Rubi es un mezclador eléctrico de productos adhesivos, cementos, resinas y pinturas, entre otros."
              />
            </div>
          </a>

          <div className="carousel-mobile-img-text">
            <p>NUEVA BATIDORA RUBIMIX - 9 SUPERTORQUE</p>
            <p>
              Consulta{" "}
              <a
                target="_blank"
                rel="noopener noreferrer "
                href={logos.rubi.redirect}
              >
                aquí
              </a>{" "}
              todas las novedades de Rubi
            </p>
          </div>
        </div>

        {/* SLIDE 3 */}
        <div>
          <a target="_blank" rel="noopener noreferrer " href="/tienda-online">
            <div>
              <img
                src="https://res.cloudinary.com/dt22oxno6/image/upload/v1629907946/sucosan/Slides/Slide_03_home_v2_vrsk6e.jpg"
                alt="Tienda online de materiales de construcción de Sucosan en el centro de Madrid y con envíos a toda España."
              />
            </div>
          </a>

          <div className="carousel-mobile-img-text">
            <p>PRÓXIMAMENTE</p>
            <p>
              Podrás comprar nuestros productos con un solo clic en nuestra
              nueva tienda online
            </p>
          </div>
        </div>

        {/* SLIDE 4 */}
        <div>
          <a target="_blank" rel="noopener noreferrer " href="/catalogo/fila">
            <div>
              <img
                src="https://res.cloudinary.com/dt22oxno6/image/upload/v1629908038/sucosan/Slides/Slide_05_home_v2_mtqbgi.jpg"
                alt="productos de Fila especializados en limpieza de cerámicas y piedras naturales."
              />
            </div>
          </a>

          <div className="carousel-mobile-img-text">
            <p>VISITA EL CATÁLOGO DE FILA</p>
            <p>
              Productos de limpieza, protección o ceras son algunos de los
              productos de{" "}
              <a
                target="_blank"
                rel="noopener noreferrer "
                href={logos.fila.redirect}
              >
                Fila
              </a>{" "}
              que puedes comprar en Sucosan
            </p>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default CarouselMobile;
